/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
import { setRentListing } from "../State/Actions/listingActions.js";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // CSS
import "ag-grid-community/styles/ag-theme-material.css"; // CSS
import "mapbox-gl/dist/mapbox-gl.css"; // CSS
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // CSS
import { Container, Row, Col, Card } from "react-bootstrap";
/*        4. Rent Scoop Libraries     */
import InitialPopup from "./Components/InitialPopup.jsx";
import ListingImagesCarousel from "./Components/ListingImagesCarousel.jsx";
import MapComponent from "./Components/Map.jsx";
import MenuBar from "./Components/MenuBar.jsx";
// import RentScatterChart from "./Components/ScatterChart.jsx";
import * as environment from "../env.js";
import "./ListingPage.css"; // CSS
import StandardDetailView from "./Components/Grid.jsx";
import DescriptiveDetailView from "./Components/DescriptiveDetailView.jsx";
import TheNavbar from "../NavBar/Navbar.jsx";

const ListingPage = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);
  // Local Storage
  const savedMapData = JSON.parse(localStorage.getItem("mapData"));
  // Redux
  const city =
    useSelector((state) => state.map.address) || savedMapData?.city || "Lowell";
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .post(environment.domain + "/listings/getRentalListingsByCity", {
        city: city,
      })
      .then((response) => {
        dispatch(setRentListing(response.data.data));
      });
  }, []);

  let detailType = useSelector((state) => state.listings.detailType);
  if (detailType === "descriptive") {
    detailType = <DescriptiveDetailView user={user} />;
  } else if (detailType === "standard") {
    detailType = <StandardDetailView user={user} />;
  } else {
    // Handle the case if detailType is not recognized
    detailType = <p>Invalid detailType!</p>;
  }

  return (
    <>
      <InitialPopup />
      <TheNavbar user={user} />
      <ListingImagesCarousel />
      <MenuBar />
      <Container fluid>
        <Row>
          <Col style={{ paddingLeft: 0, paddingRight: 0 }} xs={12} md={6}>
            <Card style={{ borderRadius: 0 }}>
              <MapComponent />
            </Card>
          </Col>

          <Col style={{ paddingLeft: 0, paddingRight: 0 }} xs={0} md={6}>
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                backgroundColor: "#3399ff",
              }}
            >
              <div
                style={{
                  height: "80vh",
                  width: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "1em",
                }}
              >
                {detailType}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ListingPage;
