/*           1. React Libraries       */
import React, { useEffect, useState } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
import {
  setLatitude,
  setLongitude,
  setAddress,
  setState,
} from "../../State/Actions/mapActions.js";
import { setRentData } from "../../State/Actions/gridActions.js";
/*           3. Third Party           */
import axios from "axios";
import { Button } from "react-bootstrap";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
/*        4. Rent Scoop Libraries     */
import * as environment from "../../env.js";
import { gridRef, columnRef } from "./Shared.jsx";

const MenuBar = () => {
  const mapData = {};
  const savedMapData = JSON.parse(localStorage.getItem("mapData"));
  const [rentMin, setRentMin] = useState(0);
  const [rentMax, setRentMax] = useState(6000);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const city =
    useSelector((state) => state.map.address) || savedMapData.city || "Lowell";
  const state =
    useSelector((state) => state.map.state) || savedMapData?.state || "MA";
  const geocoder = new MapboxGeocoder({
    accessToken: environment.mapboxgl,
    mapboxgl: mapboxgl,
    placeholder: "Enter a City",
    countries: "us",
    inputValue: "",
    input: "#searchBar",
  });
  useEffect(() => {
    initializeGeocoder();
  }, []);

  geocoder.on("result", (result) => {
    dispatch(setLatitude(parseFloat(result.result.center[1])));
    dispatch(setLongitude(parseFloat(result.result.center[0])));
    mapData.latitude = parseFloat(result.result.center[1]);
    mapData.longitude = parseFloat(result.result.center[0]);
    // City Search Result
    if (/^place\.\d+$/.test(result.result.id)) {
      const splitCode = result.result?.context[1]?.short_code?.split("-");
      const stateCode = splitCode[1];
      dispatch(setAddress(result.result.text));
      dispatch(setState(stateCode));
      mapData.city = result.result.text;
      mapData.state = stateCode;
      tagCitySearch(result.result.place_name);
      axios
        .get(environment.domain + "/records/getRentalRecords")
        .then((response) => {
          dispatch(setRentData(response.data.data));
          if (gridRef) {
            gridRef.current.onFilterChanged();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    result.result.context?.forEach((context) => {
      if (/^place\.\d+$/.test(context.id)) {
        const splitCode = result.result?.context[4]?.short_code?.split("-");
        const stateCode = splitCode[1];
        mapData.city = context.text;
        mapData.state = stateCode;
        dispatch(setAddress(context.text));
        dispatch(setState(stateCode));
        tagHouseSearch(result.result.place_name);
        axios
          .get(environment.domain + "/records/getRentalRecords")
          .then((response) => {
            dispatch(setRentData(response.data.data));
            if (gridRef) {
              gridRef.current.onFilterChanged();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
    localStorage.setItem("mapData", JSON.stringify(mapData));
  });

  geocoder.on("clear", () => {
    dispatch(setLatitude(0));
    dispatch(setLongitude(0));
  });

  function initializeGeocoder() {
    geocoder.addTo("#searchBar");
  }

  function tagFilter(type, val) {
    ReactGA.event({
      category: type + " Filter",
      action: "Clicked",
      label: val + " " + city,
    });
  }
  function tagSort(type) {
    ReactGA.event({
      category: "Rent Sort",
      action: "Clicked",
      label: city + " " + type,
    });
  }
  function tagCitySearch(type) {
    ReactGA.event({
      category: "City Search",
      action: "Searched",
      label: type,
    });
  }
  function tagHouseSearch(type) {
    ReactGA.event({
      category: "House Search",
      action: "Searched",
      label: type,
    });
  }

  function useHandleBathSelect() {
    return (event) => {
      if (gridRef) {
        tagFilter("Bath", event);
        var bathFilterComponent =
          gridRef.current.getFilterInstance("numberOfBaths");
        if (event !== "") {
          bathFilterComponent.setModel({
            type: "equals",
            filter: event,
          });
        } else {
          bathFilterComponent.setModel(null);
        }
        gridRef.current.onFilterChanged();
      }
    };
  }

  function useHandleBedSelect() {
    return (event) => {
      if (gridRef) {
        tagFilter("Bed", event);
        var bedFilterComponent =
          gridRef.current.getFilterInstance("numberOfBeds");
        if (event !== "") {
          bedFilterComponent.setModel({
            type: "equals",
            filter: event,
          });
        } else {
          bedFilterComponent.setModel(null);
        }
        gridRef.current.onFilterChanged();
      }
    };
  }

  function useHandleYearSelect() {
    return (event) => {
      if (gridRef) {
        tagFilter("Year", event);
        const year = parseInt(event) - 1;
        var yearFilterComponent =
          gridRef.current.getFilterInstance("moveInDate");
        if (event !== "") {
          yearFilterComponent.setModel({
            type: "contains",
            filter: year.toString(),
          });
        } else {
          yearFilterComponent.setModel(null);
        }
        gridRef.current.onFilterChanged();
      }
    };
  }

  function useHandleTypeSelect() {
    return (event) => {
      if (gridRef) {
        tagFilter("Home Type", event);
        var propertyFilterComponent =
          gridRef.current.getFilterInstance("propertyType");
        if (event !== "") {
          propertyFilterComponent.setModel({
            type: "equals",
            filter: event,
          });
        } else {
          propertyFilterComponent.setModel(null);
        }
        gridRef.current.onFilterChanged();
      }
    };
  }

  function handleRentMinSelect(setRentMin, event) {
    setRentMin(event.target.value);
  }

  function handleRentMaxSelect(setRentMax, event) {
    setRentMax(event.target.value);
  }

  function applyRentFilter(rentMin, rentMax) {
    if (gridRef && gridRef.current && gridRef.current) {
      tagFilter("Rent", rentMin + " to " + rentMax);
      var rentFilterComponent = gridRef.current.getFilterInstance("monthRent");
      rentFilterComponent.setModel({
        type: "inRange",
        filter: rentMin,
        filterTo: rentMax,
      });
      gridRef.current.onFilterChanged();
    }
  }

  function sortByRentAsc() {
    tagSort("Ascend");
    columnRef.current.applyColumnState({
      state: [{ colId: "monthRent", sort: "asc" }],
      defaultState: { sort: null },
    });
    gridRef.current.redrawRows();
  }

  function sortByRentDesc() {
    tagSort("Descend");
    columnRef.current.applyColumnState({
      state: [{ colId: "monthRent", sort: "desc" }],
      defaultState: { sort: null },
    });
    gridRef.current.redrawRows();
  }
  return (
    <>
      <Helmet>
        <title>
          Rental Records in {city} {state}| Rent Scoop
        </title>
        <meta
          name="description"
          content={`This is a list of all of the rental records in ${city} ${state}. This is the best tool when searching for a fair rent price in the ${city} ${state} area`}
        />
        <meta
          name="keywords"
          content={`${city} ${state} rental records, Rentals in ${city} ${state}, Fair rent price in ${city} ${state}, ${city} ${state} rent price`}
        />
      </Helmet>
      <Navbar className="filter-bar" expand="lg">
        <Navbar.Brand
          id="searchBar"
          style={{ paddingLeft: "20px" }}
        ></Navbar.Brand>
        <Nav className="ml-auto right-side"></Nav>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ marginLeft: "20px" }}
        >
          Filter
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          Filter:
          <Nav className="ml-auto right-side">
            <NavDropdown
              title="Bed"
              className="nav-link ms-3"
              id="basic-nav-dropdown"
              onSelect={useHandleBedSelect(gridRef)}
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: 0,
              }}
            >
              <NavDropdown.Item className="text-center" eventKey={""}>
                <span>Any</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={0}>
                <span>Studio</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={1}>
                <span>1</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={2}>
                <span>2</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={3}>
                <span>3</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={4}>
                <span>4</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={5}>
                <span>5</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={6}>
                <span>6</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={7}>
                <span>7</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={8}>
                <span>8</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={9}>
                <span>9</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={10}>
                <span>10</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ml-auto right-side">
            <NavDropdown
              title="Bath"
              className="nav-link ms-3"
              id="basic-nav-dropdown"
              onSelect={useHandleBathSelect(gridRef)}
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: 0,
              }}
            >
              <NavDropdown.Item className="text-center" eventKey={""}>
                <span>Any</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={1}>
                <span>1</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={1.5}>
                <span>1.5</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={2}>
                <span>2</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={2.5}>
                <span>2.5</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={3}>
                <span>3</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={3.5}>
                <span>3.5</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={4}>
                <span>4</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ml-auto right-side">
            <NavDropdown
              title="Home Type"
              className="nav-link ms-3"
              onSelect={useHandleTypeSelect(gridRef)}
              id="basic-nav-dropdown"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: 0,
              }}
            >
              <NavDropdown.Item className="text-center" eventKey={""}>
                <span>Any</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"Sublet"}>
                <span>Sublet</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"Attached"}>
                <span>Attached</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"Apartment"}>
                <span>Apartment</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"Condominium"}
              >
                <span>Condominium</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"Single Family"}
              >
                <span>Single Family</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"Multi Family"}
              >
                <span>Multi Family</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ml-auto right-side">
            <NavDropdown
              title="Rent Cost"
              className="nav-link ms-3"
              id="basic-nav-dropdown"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: 0,
              }}
            >
              <div className="text-center rent-price">
                Min{" "}
                <select
                  onChange={(event) => handleRentMinSelect(setRentMin, event)}
                  style={{
                    width: "100px",
                    marginRight: "1em",
                  }}
                >
                  <option value={0}>Any</option>
                  <option value={1000}>$1,000</option>
                  <option value={1500}>$1,500</option>
                  <option value={2000}>$2,000</option>
                  <option value={2500}>$2,500</option>
                  <option value={3000}>$3,000</option>
                  <option value={3500}>$3,500</option>
                  <option value={4000}>$4,000</option>
                  <option value={4500}>$4,500</option>
                  <option value={5000}>$5,000</option>
                  <option value={5500}>$5,500</option>
                  <option value={6000}>$6,000</option>
                </select>
                Max{" "}
                <select
                  onChange={(event) => handleRentMaxSelect(setRentMax, event)}
                  style={{
                    width: "100px",
                    marginBottom: "1em",
                  }}
                >
                  <option value={6000}>Any</option>
                  <option value={1000}>$1,000</option>
                  <option value={1500}>$1,500</option>
                  <option value={2000}>$2,000</option>
                  <option value={2500}>$2,500</option>
                  <option value={3000}>$3,000</option>
                  <option value={3500}>$3,500</option>
                  <option value={4000}>$4,000</option>
                  <option value={4500}>$4,500</option>
                  <option value={5000}>$5,000</option>
                  <option value={5500}>$5,500</option>
                  <option value={6000}>$6,000</option>
                </select>
                <Button
                  variant="primary"
                  onClick={(event) => applyRentFilter(rentMin, rentMax)}
                >
                  Apply
                </Button>
              </div>
            </NavDropdown>
          </Nav>
          <Nav className="ml-auto right-side">
            <NavDropdown
              title="Year"
              className="nav-link ms-3"
              id="basic-nav-dropdown"
              onSelect={useHandleYearSelect(gridRef)}
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: 0,
              }}
            >
              <NavDropdown.Item className="text-center" eventKey={""}>
                <span>All</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"2019"}>
                <span>2018</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"2020"}>
                <span>2019</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"2021"}>
                <span>2020</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"2022"}>
                <span>2021</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"2023"}>
                <span>2022</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="text-center" eventKey={"2024"}>
                <span>2023</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ms-2">Sort By Cost:</Nav>
          <Nav className="ml-auto right-side ms-2">
            <Button variant="primary" onClick={() => sortByRentAsc()}>
              Low - High
            </Button>
          </Nav>
          <Nav className="ml-auto right-side ms-2">
            <Button variant="primary" onClick={() => sortByRentDesc()}>
              High - Low
            </Button>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand>
          <Button
            onClick={() => navigate("/addRental")}
            style={{ backgroundColor: "#00c720", border: "none" }}
          >
            + Add Yours
          </Button>
        </Navbar.Brand>
      </Navbar>
    </>
  );
};
export default MenuBar;
