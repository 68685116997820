/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // CSS
import "ag-grid-community/styles/ag-theme-material.css"; // CSS
import "mapbox-gl/dist/mapbox-gl.css"; // CSS
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // CSS
import { Helmet } from "react-helmet";
import { FaTimes } from "react-icons/fa";
import * as environment from "../../env.js";
import { setPopupCarousel } from "../../State/Actions/universityActions";
import ListingImagesCarousel from "./ListingsComponents/ListingImagesCarousel.jsx";
/*           3. Third Party           */
import mapboxgl from "mapbox-gl";
import { AgGridReact } from "ag-grid-react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
/*        4. Rent Scoop Libraries     */
import { gridRef, columnRef, scatterChartRef } from "./Shared.jsx";
import * as Constant from "../../constants.js";
import {
  Card,
  Row,
  Col,
  Carousel,
  Navbar,
  Nav,
  Container,
  NavDropdown,
} from "react-bootstrap";
import {
  setDetailType,
  setSingleRental,
  setGridState,
} from "../../State/Actions/listingActions";
import { FaRegHeart, FaHeart } from "react-icons/fa";
const SingleLandlord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { universityID, landlordID } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    axios
      .get(`${environment.domain}/user/getDetailedUser?user=${landlordID}`, {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching university details:", error);
      });
  }, []);

  const listings =
    user?.listings?.filter(
      (listing) => listing.isReviewed && listing.isApproved && listing.isActive,
    ) || [];

  const savedMapData = JSON.parse(localStorage.getItem("mapData"));
  const city =
    useSelector((state) => state.map.address) || savedMapData?.city || "Lowell";
  const state =
    useSelector((state) => state.map.state) || savedMapData?.state || "MA";
  const [filteredListings, setFilteredListings] = useState(null);
  const [currentSortOption, setCurrentSortOption] = useState("");
  const map = useSelector((state) => state.map.map);
  const savedGridState = useSelector((state) => state.listings.gridState);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    columnRef.current = params.columnApi;
    buildAndAttachMapMarkers(listings);
    if (savedGridState && gridRef.current && columnRef.current) {
      if (savedGridState.filterModel) {
        gridRef.current.setFilterModel(savedGridState.filterModel);
      }
      if (savedGridState.sortModel) {
        columnRef.current.applyColumnState({
          state: savedGridState.sortModel,
          defaultState: { sort: null },
        });
      }
    }
  };

  const onFilterChanged = () => {
    const filteredRows = [];
    gridRef.current.forEachNodeAfterFilter((node) => {
      filteredRows.push(node.data);
    });
    buildAndAttachMapMarkers(filteredRows);
    setFilteredListings(filteredRows);
    gridRef.current.redrawRows();
    const currentFilterModel = gridRef.current.getFilterModel();
    const allColumnState = columnRef.current.getColumnState();

    const currentSortModel = allColumnState
      .filter((colState) => colState.sort != null)
      .map((colState) => ({
        colId: colState.colId,
        sort: colState.sort,
        sortIndex: colState.sortIndex,
      }));
    dispatch(
      setGridState({
        sortModel: currentSortModel,
        filterModel: currentFilterModel,
      }),
    );
  };

  const onSortChanged = () => {
    const sortedData = [];
    gridRef.current.forEachNodeAfterFilterAndSort((node) => {
      sortedData.push(node.data);
    });
    setFilteredListings(sortedData);
    const currentFilterModel = gridRef.current.getFilterModel();
    const allColumnState = columnRef.current.getColumnState();
    const currentSortModel = allColumnState
      .filter((colState) => colState.sort != null)
      .map((colState) => ({
        colId: colState.colId,
        sort: colState.sort,
        sortIndex: colState.sortIndex,
      }));
    dispatch(
      setGridState({
        sortModel: currentSortModel,
        filterModel: currentFilterModel,
      }),
    );
  };

  const getSortOptionText = (eventKey) => {
    const sortOptions = {
      costLowHigh: "$ - $$$",
      costHighLow: "$$$ - $",
      bedCount: "Bed Count",
      bathCount: "Bath Count",
    };
    return sortOptions[eventKey] || "";
  };

  function buildAndAttachMapMarkers(listings) {
    const markers = document.getElementsByClassName("marker-element");
    while (markers.length > 0) {
      markers[0].parentNode.removeChild(markers[0]);
    }
    listings.forEach((rental) => {
      const markerElement = document.createElement("div");
      markerElement.className = "marker-element";
      markerElement.innerText = "$" + rental.monthRent;
      markerElement.style.backgroundColor = "#333399";
      markerElement.style.color = "white";
      markerElement.style.borderRadius = "10px";

      const addressParse = rental.address.split(",");
      const address = addressParse.slice(0, 2);

      const popupContent = `
  <div id="popup-content">
    <p style="margin-bottom: -5px;"><strong>${address.join(", ")}</strong></p>
    <p style="margin-bottom: -5px;"><strong>Rent:</strong> $${
      rental.monthRent
    }</p>
    <p style="margin-bottom: -5px;">$${(
      rental.monthRent / rental.numberOfBeds
    ).toFixed(0)}/bed</p>
    <p style="margin-bottom: -5px;">${
      rental.numberOfBeds
    } bd &nbsp&nbsp|&nbsp&nbsp${rental.numberOfBaths} ba</p>
    ${
      rental.utilitiesIncluded
        ? `<p style="margin-bottom: -5px;"><strong>Included Utilities:</strong> ${rental.utilitiesIncluded}</p>`
        : ""
    }
    ${
      rental.perks
        ? `<p style="margin-bottom: -5px;"><strong>Perks:</strong> ${rental.perks}</p>`
        : ""
    }
    ${
      rental.sqft
        ? `<p style="margin-bottom: -5px;"><strong>SqFt:</strong> ${rental.sqft}</p>`
        : ""
    }
  </div>
`;

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([rental.longitude, rental.latitude])
        .setPopup(popup)
        .addTo(map);

      markerElement.addEventListener("click", () => {
        dispatch(setDetailType("descriptive"));
        dispatch(setSingleRental(rental));
        marker.togglePopup();
      });
    });
  }

  function useHandleSortSelect() {
    return (eventKey) => {
      if (gridRef && columnRef && columnRef.current) {
        let sortState;
        switch (eventKey) {
          case "costLowHigh":
            sortState = { colId: "monthRent", sort: "asc" };
            break;
          case "costHighLow":
            sortState = { colId: "monthRent", sort: "desc" };
            break;
          case "bedCount":
            sortState = { colId: "numberOfBeds", sort: "asc" };
            break;
          case "bathCount":
            sortState = { colId: "numberOfBaths", sort: "asc" };
            break;
          default:
            sortState = null;
            break;
        }

        columnRef.current.applyColumnState({
          state: sortState ? [sortState] : [],
          defaultState: { sort: null },
        });
      }
      setCurrentSortOption(eventKey);
    };
  }
  const handleBackClick = () => {
    navigate(`/universities/${universityID}/landlords/`);
  };

  const handleListingClick = (listing) => {
    navigate(`/universities/${universityID}/listings/${listing._id}`);
  };
  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        {" "}
        {/* Ensure this div spans the full page width */}
        <FaTimes
          size={24}
          color="white"
          style={{
            position: "absolute", // Position absolutely within the relative container
            top: "16px", // Adjust as needed for your layout
            right: "16px", // Adjust as needed for your layout
            cursor: "pointer",
            zIndex: 10, // Ensure it's above other elements
          }}
          onClick={handleBackClick}
        />
        <Container className="d-flex justify-content-center mt-2">
          <Card
            style={{
              borderRadius: "10px",
              backgroundColor: "#3399FF",
              border: "0px",
              maxWidth: "75%", // Adjust card size as necessary
            }}
          >
            <Card.Body>
              <div className="d-flex flex-column align-items-center">
                <img
                  src={
                    user?.pfp ||
                    "https://yourteachingmentor.com/wp-content/uploads/2020/12/istockphoto-1223671392-612x612-1.jpg"
                  }
                  alt="Profile"
                  style={{
                    width: "150px", // Adjust image size as necessary
                    height: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginBottom: "10px", // Gives some space below the image
                  }}
                />
                <div style={{ color: "white", textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {user?.name}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "24px" }}>
                    {user?.bio}
                  </p>
                  {user?.contact?.displayEmail && user?.contact?.email && (
                    <p style={{ fontSize: "14px", marginTop: "-20px" }}>
                      <strong>Email: </strong>
                      {user?.contact?.email}
                    </p>
                  )}
                  {user?.contact?.displayPhone && user?.contact?.phone && (
                    <p style={{ fontSize: "14px", marginTop: "-20px" }}>
                      <strong>Phone: </strong>
                      {user?.contact?.phone}
                    </p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
      <div
        style={{
          display: "none",
        }}
      >
        <AgGridReact
          ref={gridRef}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          rowData={listings}
          columnDefs={useColumnDefs()}
          rowHeight={40}
          onRowClicked={(event) => {
            const record = event.data;
            map.flyTo({
              center: [record.longitude, record.latitude],
              zoom: 17,
            });
          }}
          floatingFiltersHeight={20}
          suppressCellFlash={true}
          onGridReady={onGridReady}
          rowClassRules={(params) => {
            if (params.node.rowIndex % 2 === 0) {
              return { color: Constant.EVEN_ROW_COLOR };
            } else {
              return { color: Constant.ODD_ROW_COLOR };
            }
          }}
        ></AgGridReact>
      </div>
      <div className="standard-detail-view">
        {/* First Row */}
        <Row>
          <Col>
            <h1
              style={{ fontWeight: "bold", fontSize: "28px", color: "white" }}
            >
              {user?.name}'s Rental Listings
            </h1>
          </Col>
        </Row>
        {/* Second Row */}
        <Row className="align-items-center">
          <Col xs={4} style={{ color: "white" }}>
            {filteredListings ? filteredListings.length : listings.length}{" "}
            Results
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center justify-content-end"
            style={{ color: "white" }}
          >
            <span style={{ marginRight: "10px", fontWeight: "bold" }}>
              Sort By:
            </span>

            <NavDropdown
              title={getSortOptionText(currentSortOption)}
              onSelect={useHandleSortSelect()}
              style={{
                boxShadow: "none",
              }}
              className="p-0 bg-transparent border-0"
            >
              <NavDropdown.Item
                className="text-center"
                eventKey={"costLowHigh"}
                onSelect={() => setCurrentSortOption("costLowHigh")}
              >
                $ - $$$
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"costHighLow"}
                onSelect={() => setCurrentSortOption("costHighLow")}
              >
                $$$ - $
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"bedCount"}
                onSelect={() => setCurrentSortOption("bedCount")}
              >
                Bed Count
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"bathCount"}
                onSelect={() => setCurrentSortOption("bathCount")}
              >
                Bath Count
              </NavDropdown.Item>
            </NavDropdown>
          </Col>
        </Row>
        {/* Listings */}
        <Row>
          {(filteredListings ? filteredListings : listings).map(
            (listing, index) => (
              <Listing
                key={listing.id | index}
                user={user}
                listing={listing}
                handleListingClick={handleListingClick}
              />
            ),
          )}
        </Row>
      </div>
    </>
  );
};

export default SingleLandlord;

const defaultImage = [
  "https://th.bing.com/th/id/R.b7086134885bf7b52030aa4513d6a574?rik=WhI5peBNz5lcdg&riu=http%3a%2f%2fcliparts.co%2fcliparts%2f8TG%2fEr6%2f8TGEr6r7c.png&ehk=PYIpC1OwRjT43jJFNDf4Sm%2fZwQG8HlG3Nw6Dk0P6z0Q%3d&risl=&pid=ImgRaw&r=0",
];

const Listing = ({ listing, user, handleListingClick }) => {
  const imageToUse = listing.coverImage ? listing.coverImage : defaultImage;
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    // Check if the listing ID is in the user's savedListings to determine favorite state
    setFavorite(user?.savedListings?.includes(listing._id));
  }, [user, listing._id]);
  const handleToggleFavorite = async () => {
    if (favorite) {
      await handleRemoveFromSaved();
    } else {
      await handleAddToSaved();
    }
  };

  const handleAddToSaved = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/user/addSavedListing`,
        listing,
        { withCredentials: true },
      );
      setFavorite(true);
    } catch (error) {
      console.error("Error adding to saved listings:", error);
    }
  };

  const handleRemoveFromSaved = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/user/removeSavedListing`,
        listing,
        { withCredentials: true },
      );
      setFavorite(false);
    } catch (error) {
      console.error("Error removing from saved listings:", error);
    }
  };
  return (
    <Col
      md={3}
      className="listing-col"
      style={{ padding: "0.5%", margin: "0" }}
    >
      <Card
        className="listing-card"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "275px",
          overflow: "hidden",
          margin: "0",
          padding: "0",
          cursor: "pointer",
        }}
      >
        <div style={{ position: "relative", overflow: "hidden" }}>
          <img
            src={listing.coverImage || defaultImage}
            alt="Listing Cover"
            style={{ width: "100%", minHeight: "200px", objectFit: "cover" }}
          />
          {favorite ? (
            <FaHeart
              className="heart-icon"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "1.5rem",
                color: "red",
              }}
              onClick={handleToggleFavorite}
            />
          ) : (
            <FaRegHeart
              className="heart-icon"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "1.5rem",
                color: "white",
              }}
              onClick={handleToggleFavorite}
            />
          )}
        </div>
        <Card.Body
          onClick={() => handleListingClick(listing)}
          style={{ marginTop: "-10px", height: "30%" }}
        >
          <Card.Title style={{ marginBottom: "1px", fontWeight: "bold" }}>
            ${listing.monthRent}
          </Card.Title>
          <Card.Text
            style={{ marginBottom: "1px", padding: "0", fontSize: "smaller" }}
          >
            {listing.numberOfBeds} bds | {listing.numberOfBaths} ba |{" "}
            {listing.sqft} sqft
          </Card.Text>
          <Card.Text
            style={{ marginBottom: "1px", padding: "0", fontSize: "smaller" }}
          >
            {listing.address.split(",")[0]}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

class RentCellRenderer extends React.Component {
  constructor(props) {
    super(props);
    props.api.selectAll();
  }

  render() {
    const cellStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor:
        this.props.node.rowIndex % 2 === 0
          ? Constant.EVEN_ROW_COLOR
          : Constant.ODD_ROW_COLOR,
    };

    const topLineStyle = {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(13, 110, 253)",
      marginBottom: "-20px",
    };

    const bottomLineStyle = {
      fontSize: "10px",
      fontStyle: "italic",
      marginTop: "1px",
    };
    return (
      <div style={cellStyle}>
        <div style={topLineStyle}>${this.props.value}</div>
        <div style={bottomLineStyle}>
          {" "}
          $
          {this.props.data.numberOfBeds
            ? Number(
                (this.props.value / this.props.data.numberOfBeds).toFixed(0),
              )
            : Number(this.props.value.toFixed(0))}
          /bed
        </div>
      </div>
    );
  }
}

function useColumnDefs() {
  const [columnDefs] = useState([
    {
      field: "monthRent",
      headerName: "Rent",
      headerClass: "centered-header",
      sortable: true,
      flex: 2,
      suppressMenu: true,
      filter: "agNumberColumnFilter",
      suppressMovable: true,
      cellRenderer: RentCellRenderer,
      cellStyle: (params) => ({
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBeds",
      headerName: "Beds",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      valueFormatter: (params) => {
        const studio = params.value;
        if (studio === 0) {
          return "Studio";
        } else {
          return params.value;
        }
      },
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBaths",
      headerName: "Baths",
      headerClass: "centered-header",
      filter: "agNumberColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "moveInDate",
      headerName: "Lease Start",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: false },
      suppressMenu: true,
      sortable: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
      valueGetter: (params) => {
        let date = params.data.moveInDate;
        if (date) {
          const year = new Date(date).getFullYear();
          const month = new Date(date).toLocaleDateString("en-US", {
            month: "short",
          });
          return `${month} ${year}`;
        } else {
          return null;
        }
      },
    },
    {
      field: "propertyType",
      headerName: "Type",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
  ]);
  return columnDefs;
}
