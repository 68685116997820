/*           1. React Libraries       */
import React, { useState } from "react";
/*           2. Redux Libraries       */
import { useSelector } from "react-redux";
/*           3. Third Party           */
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AgGridReact } from "ag-grid-react";
/*        4. Rent Scoop Libraries     */
import { gridRef, columnRef, scatterChartRef } from "./Shared.jsx";
import * as Constant from "../../../constants.js";

const RentAgGrid = (props) => {
  const university = props.props.university;
  const rentData =
    university?.records?.filter(
      (record) => record.isReviewed && record.isApproved,
    ) || [];
  const map = useSelector((state) => state.map.map);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    columnRef.current = params.columnApi;
  };
  const onFilterChanged = () => {
    const filteredRows = [];
    gridRef.current.forEachNodeAfterFilter((node) => {
      filteredRows.push(node.data);
    });
    gridRef.current.redrawRows();
  };

  return (
    <AgGridReact
      ref={gridRef}
      onFilterChanged={onFilterChanged}
      rowData={rentData}
      columnDefs={useColumnDefs()}
      rowHeight={40}
      floatingFiltersHeight={20}
      suppressCellFlash={true}
      onGridReady={onGridReady}
      rowClassRules={(params) => {
        if (params.node.rowIndex % 2 === 0) {
          return { color: Constant.EVEN_ROW_COLOR };
        } else {
          return { color: Constant.ODD_ROW_COLOR };
        }
      }}
    ></AgGridReact>
  );
};
export default RentAgGrid;
class RentCellRenderer extends React.Component {
  constructor(props) {
    super(props);
    props.api.selectAll();
  }

  render() {
    const cellStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor:
        this.props.node.rowIndex % 2 === 0
          ? Constant.EVEN_ROW_COLOR
          : Constant.ODD_ROW_COLOR,
    };

    const topLineStyle = {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(13, 110, 253)",
      marginBottom: "-20px",
    };

    const bottomLineStyle = {
      fontSize: "10px",
      fontStyle: "italic",
      marginTop: "1px",
    };
    return (
      <div style={cellStyle}>
        <div style={topLineStyle}>${this.props.value}</div>
        <div style={bottomLineStyle}>
          {" "}
          $
          {this.props.data.numberOfBeds
            ? Number(
                (this.props.value / this.props.data.numberOfBeds).toFixed(0),
              )
            : Number(this.props.value.toFixed(0))}
          /bed
        </div>
      </div>
    );
  }
}

function useColumnDefs() {
  const [columnDefs] = useState([
    {
      field: "monthRent",
      headerName: "Rent",
      headerClass: "centered-header",
      sortable: true,
      flex: 2,
      suppressMenu: true,
      filter: "agNumberColumnFilter",
      suppressMovable: true,
      cellRenderer: RentCellRenderer,
      cellStyle: (params) => ({
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBeds",
      headerName: "Beds",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      valueFormatter: (params) => {
        const studio = params.value;
        if (studio === 0) {
          return "Studio";
        } else {
          return params.value;
        }
      },
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBaths",
      headerName: "Baths",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "moveInDate",
      headerName: "Lease Start",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: false },
      suppressMenu: true,
      sortable: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
      valueGetter: (params) => {
        let date = params.data.moveInDate;
        if (date) {
          const year = new Date(date).getFullYear();
          const month = new Date(date).toLocaleDateString("en-US", {
            month: "short",
          });
          return `${month} ${year}`;
        } else {
          return null;
        }
      },
    },
    {
      field: "propertyType",
      headerName: "Type",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
  ]);
  return columnDefs;
}
