import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUniversity } from "../../State/Actions/universityActions.js";
import * as environment from "../../env.js";
import axios from "axios";

// Simple debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const UniversityTypeAhead = (props) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [isSelectionMade, setIsSelectionMade] = useState(false); // New state to track if a selection has been made

  // Debounce fetchSuggestions method
  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

  useEffect(() => {
    if (input.length > 2 && !isSelectionMade) {
      // Check if a selection was made
      debouncedFetchSuggestions(input);
    } else {
      setSuggestions([]);
    }
  }, [input, isSelectionMade]); // Add isSelectionMade as a dependency

  async function fetchSuggestions(query) {
    try {
      const response = await axios.get(
        environment.domain +
          `/university/universityLookup?query=${encodeURIComponent(query)}`,
      );
      const topFiveSuggestions = response.data.data.slice(0, 5);
      setSuggestions(topFiveSuggestions);
      setIsSelectionMade(false); // Reset selection state on fetching suggestions
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  const onKeyDown = (e) => {
    setIsSelectionMade(false);
    if (e.keyCode === 13) {
      // Enter key
      setInput(suggestions[activeSuggestionIndex]);
      setSuggestions([]);
    } else if (e.keyCode === 38) {
      // Up arrow
      if (activeSuggestionIndex === 0) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (e.keyCode === 40) {
      // Down arrow
      if (activeSuggestionIndex - 1 === suggestions.length) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder="Search University"
        style={{
          width: "100%",
          borderRadius: "10px",
          padding: "10px", // Optional: Adds some padding inside the input box for better aesthetics
        }}
      />
      {suggestions.length > 0 && (
        <div
          className="suggestions-container"
          style={{
            border: "1px solid #ddd",
            marginTop: "8px",
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {suggestions.map((suggestion, index) => (
              <li
                key={suggestion._id}
                style={{
                  padding: "10px",
                  backgroundColor:
                    activeSuggestionIndex === index ? "#f7f7f7" : "#fff",
                }}
                onClick={() => {
                  setInput(suggestion.name); // Use the name property of suggestion
                  setSuggestions([]);
                  setIsSelectionMade(true);
                  props.handleUniversitySelection(suggestion);
                }}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UniversityTypeAhead;
