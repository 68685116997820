/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // CSS
import "ag-grid-community/styles/ag-theme-material.css"; // CSS
import "mapbox-gl/dist/mapbox-gl.css"; // CSS
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // CSS
import { Container, Row, Col, Card, Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, useLocation } from "react-router-dom";
/*        4. Rent Scoop Libraries     */
// import RentScatterChart from "./Components/ScatterChart.jsx";
import * as environment from "../env.js";
import "./MyAccountPage.css"; // CSS
import TheNavbar from "../NavBar/Navbar.jsx";
import EditProfile from "./Components/EditProfile/EditProfile.jsx";
import SavedListingPage from "./Components/SavedListings/SavedListingPage.jsx";
import ManageListingsPage from "./Components/ManageListings/ManageListingsPage.jsx";

const MyAccountPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUniversityPath = `/account`;
  const [user, setUser] = useState({});
  const location = useLocation();

  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);

  let content;
  if (location.pathname.includes("/editProfile")) {
    content = (
      <Col
        xs={12}
        md={10}
        style={{ padding: "0", backgroundColor: "#3399ff", minHeight: "100vh" }}
      >
        <EditProfile user={user} />
      </Col>
    );
  } else if (location.pathname.includes("/savedListings")) {
    content = (
      <Col
        xs={12}
        md={10}
        style={{ padding: "0", backgroundColor: "#3399ff", minHeight: "100vh" }}
      >
        <SavedListingPage user={user} />
      </Col>
    );
  } else if (location.pathname.includes("/manageListings")) {
    content = (
      <Col
        xs={12}
        md={10}
        style={{ padding: "0", backgroundColor: "#3399ff", minHeight: "100vh" }}
      >
        <ManageListingsPage user={user} />
      </Col>
    );
  }
  // else if (location.pathname.includes("/landlords")) {
  //   content = <Landlords university={university} />;
  // } else if (location.pathname.includes("/roommates")) {
  //   content = <Roommates university={university} />;
  // }
  else {
    // Default content or redirect, etc.
    content = <p>You caught me</p>;
  }

  return (
    <>
      <Helmet>
        <title>{user.name ? user.name : "Account"} | Rent Scoop</title>
        <meta name="description" content={``} />
        <meta name="keywords" content={``} />
      </Helmet>
      <TheNavbar user={user} />
      <Container fluid>
        <Row>
          <Col
            xs={12}
            md={2}
            className="sidebar"
            style={{ backgroundColor: "#333399", color: "white" }}
          >
            {/* Ensure your Navbar is correctly imported from react-bootstrap */}
            <Navbar
              collapseOnSelect
              expand="lg"
              className="flex-lg-column"
              style={{ backgroundColor: "#333399", color: "white" }}
            >
              <Navbar.Toggle
                aria-controls="sidebar-nav"
                className="align-toggle-right"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                <Navbar.Collapse id="sidebar-nav">
                  <Nav className="flex-lg-column">
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/editProfile`}
                    >
                      Edit Profile
                    </Nav.Link>
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/manageListings`}
                    >
                      Manage My Listings
                    </Nav.Link>
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/savedListings`}
                    >
                      Saved Listings
                    </Nav.Link>
                    {/*
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/records`}
                    >
                      Saved Records 
                    </Nav.Link> */}
                    {/* <Nav.Link className="nav-link-custom" href="#landlord">Find Landlord</Nav.Link>
                  <Nav.Link className="nav-link-custom" href="#roommate">Find Roommate</Nav.Link>
                  <Nav.Link className="nav-link-custom" href="#scoop">The Scoop</Nav.Link> */}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </Col>
          {content}
        </Row>
      </Container>
    </>
  );
};
export default MyAccountPage;
