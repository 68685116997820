// Home page
export const EVEN_ROW_COLOR = "#f8f8f8";
export const ODD_ROW_COLOR = "#f0f0f0";

//#00c720

// Form Page
export const utilityOptions = [
  { label: "None", value: "" },
  { label: "Electricity", value: "Electricity" },
  { label: "Heating Electric", value: "Heating Electric" },
  { label: "Heating Gas", value: "Heating Gas" },
  { label: "Sewage", value: "Sewage" },
  { label: "Water", value: "Water" },
];
export const perksOptions = [
  { label: "None", value: "" },
  { label: "Parking", value: "Parking" },
  { label: "In-Unit Laundry", value: "In-Unit Laundry" },
  { label: "Pets", value: "Pets" },
  { label: "Gym", value: "Gym" },
];
export const statesOptions = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export const propertyTypesOptions = [
  { value: "Sublet", key: "Sublet" },
  { value: "Attached", key: "Attached" },
  { value: "Apartment", key: "Apartment" },
  { value: "Condominium", key: "Condominium" },
  { value: "Single Family", key: "Single Family" },
  { value: "Multi Family", key: "Multi Family" },
];
export const bedNumsOptions = [
  { value: 0, key: "Studio" },
  { value: 1, key: "1" },
  { value: 2, key: "2" },
  { value: 3, key: "3" },
  { value: 4, key: "4" },
  { value: 5, key: "5" },
  { value: 6, key: "6" },
  { value: 7, key: "7" },
  { value: 8, key: "8" },
  { value: 9, key: "9" },
  { value: 10, key: "10" },
];
export const bathNumsOptions = [
  { value: 1, key: "1" },
  { value: 1.5, key: "1.5" },
  { value: 2, key: "2" },
  { value: 2.5, key: "2.5" },
  { value: 3, key: "3" },
  { value: 3.5, key: "3.5" },
  { value: 4, key: "4" },
];

// Scatter Chart.js
export const emptyData = {
  labels: ["", "", "", "", ""],
  datasets: [
    {
      label: "Example Data",
      data: [],
      backgroundColor: "rgba(75,192,192,1)",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  ],
};

// About Page
