/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
/*           3. Rent Scoop            */
import HomePage from "./HomePage/HomePage.jsx";
import ListingPage from "./ListingPage/ListingPage.jsx";
import FormPage from "./FormPage/FormPage.jsx";
import AboutUsPage from "./AboutUsPage/AboutUsPage.jsx";
import FAQPage from "./FAQPage/FAQPage.jsx";
import ContactPage from "./ContactPage/ContactPage.jsx";
// import LandingPage from "./LandingPage/LandingPage.jsx";
import LoginPage from "./LoginPage/LoginPage.jsx";
import CreateListing from "./CreateListingPage/CreateListingPage.jsx";
import UniversitiesPage from "./UniversitiesPage/UniversitiesPage.jsx";
import MyAccountPage from "./MyAccountPage/MyAccountPage.jsx";
const MyRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<ListingPage />}></Route>
        <Route exact path="/records" element={<HomePage />}></Route>
        <Route exact path="/listings" element={<ListingPage />}></Route>
        <Route exact path="/addRental" element={<FormPage />}></Route>
        <Route exact path="/createListing" element={<CreateListing />}></Route>
        <Route
          exact
          path="/universities"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/records"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/listings"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/landlords"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/landlords/:landlordID"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/roommates"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/roommates/:studentID"
          element={<UniversitiesPage />}
        ></Route>
        <Route
          exact
          path="/universities/:universityID/listings/:listingID"
          element={<UniversitiesPage />}
        ></Route>
        <Route exact path="/account" element={<MyAccountPage />}></Route>
        <Route
          exact
          path="/account/editProfile"
          element={<MyAccountPage />}
        ></Route>
        <Route
          exact
          path="/account/savedListings"
          element={<MyAccountPage />}
        ></Route>
        <Route
          exact
          path="/account/manageListings"
          element={<MyAccountPage />}
        ></Route>
        <Route exact path="/aboutUs" element={<AboutUsPage />}></Route>
        <Route exact path="/FAQ" element={<FAQPage />}></Route>
        <Route exact path="/contact" element={<ContactPage />}></Route>
        <Route exact path="/login" element={<LoginPage />}></Route>
      </Routes>
    </Router>
  );
};

export default MyRouter;
