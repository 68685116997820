import { createSlice, combineReducers } from "@reduxjs/toolkit";

const rentListingSlice = createSlice({
  name: "rentListing",
  initialState: [],
  reducers: {
    setRentListing(state, action) {
      return action.payload;
    },
  },
});

const singleRentalSlice = createSlice({
  name: "singleRental",
  initialState: {},
  reducers: {
    setSingleRental(state, action) {
      return action.payload;
    },
  },
});

const detailTypeSlice = createSlice({
  name: "detailType",
  initialState: "standard",
  reducers: {
    setDetailType(state, action) {
      return action.payload;
    },
  },
});

const gridStateSlice = createSlice({
  name: "gridState",
  initialState: null,
  reducers: {
    setGridState(state, action) {
      return action.payload;
    },
  },
});

const popupCarouselSlice = createSlice({
  name: "popupCarousel",
  initialState: "disabled",
  reducers: {
    setPopupCarousel(state, action) {
      return action.payload;
    },
  },
});

const popupImagesSlice = createSlice({
  name: "popupImages",
  initialState: [],
  reducers: {
    setPopupImages(state, action) {
      return action.payload;
    },
  },
});

// Exports
export const listingReducers = combineReducers({
  rentListing: rentListingSlice.reducer,
  singleRental: singleRentalSlice.reducer,
  detailType: detailTypeSlice.reducer,
  gridState: gridStateSlice.reducer,
  popupCarousel: popupCarouselSlice.reducer,
  popupImages: popupImagesSlice.reducer,
});
export const { setRentListing: setRentListing } = rentListingSlice.actions;
export const { setSingleRental: setSingleRental } = singleRentalSlice.actions;
export const { setDetailType: setDetailType } = detailTypeSlice.actions;
export const { setGridState: setGridState } = gridStateSlice.actions;
export const { setPopupCarousel: setPopupCarousel } =
  popupCarouselSlice.actions;
export const { setPopupImages: setPopupImages } = popupImagesSlice.actions;
