/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { setLoginType } from "../State/Actions/loginActions.js";
import { ToastAlert } from "../Toast";
import axios from "axios";
import * as environment from "../env.js";
import { useNavigate } from "react-router-dom";
/*           3. Rent Scoop            */
import "./navbar.css";

const TheNavbar = (user) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  user = user.user;
  useEffect(() => {
    if (user.username) {
      setIsLoggedIn(true);
    }
    ReactGA.pageview(location.pathname + location.search);
  }, [location, user]);

  function gotoSignUp() {
    dispatch(setLoginType("signUp"));
  }

  function gotoSignIn() {
    dispatch(setLoginType("signIn"));
  }

  function logout() {
    axios
      .post(
        environment.domain + "/user/logout",
        {},
        {
          withCredentials: true, // needed for sessions
        },
      )
      .then((response) => {
        user = {};
        setIsLoggedIn(false);
        navigate("/login");
        ToastAlert("Goodbye!", "danger");
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }

  // const isHomePath = location.pathname === "/";
  // // Render no Navbar on the home path
  // if (isHomePath) {
  //   return null;
  // }
  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Navbar.Brand style={{ paddingLeft: "20px" }}>
        <Link to="/" className="nav-link">
          <img
            src="https://rentscoop.s3.amazonaws.com/branding/Rent-Scoop-Navbar.svg"
            alt="Logo"
            className="top-left-logo"
          ></img>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {/* Map Page aka Home Page */}
          <Link to="/records" className="nav-link">
            Records
          </Link>
          <Link to="/listings" className="nav-link">
            Listings
          </Link>
          <Link to="/universities" className="nav-link">
            Universities
          </Link>
          <Link to="/aboutUs" className="nav-link">
            About Us
          </Link>
          <Link to="/FAQ" className="nav-link">
            FAQ
          </Link>
          <Link to="/contact" className="nav-link">
            Contact
          </Link>
        </Nav>
        {isLoggedIn ? (
          <Nav className="sign-in-navbar ml-auto right-side">
            <NavDropdown
              title={`Welcome Back ${user?.name}`}
              className="nav-link ms-3"
              id="basic-nav-dropdown"
              style={{
                border: "1px solid #3399ff",
                borderRadius: "5px",
                padding: "0",
              }}
            >
              <NavDropdown.Item>
                <Link to="/account/editProfile" className="nav-link">
                  My Account
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/addRental" className="nav-link">
                  Add Record
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/createListing" className="nav-link">
                  Create Listing
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey="logout"
                onClick={logout}
              >
                <span>Logout</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        ) : (
          <Nav className="sign-in-navbar">
            <Link to="/login" onClick={gotoSignIn} className="nav-link">
              Login
            </Link>
            <Link to="/login" onClick={gotoSignUp} className="nav-link">
              Sign Up
            </Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TheNavbar;
