import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginType } from "../../State/Actions/loginActions";
import "../LoginPage.css";
import { ToastAlert } from "../../Toast";
import axios from "axios";
import * as environment from "../../env.js";

const SignInPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function gotoForgotPassword() {
    dispatch(setLoginType("forgotPassword"));
  }
  function gotoSignUp() {
    dispatch(setLoginType("signUp"));
  }
  function login() {
    let loginInfo = {
      username: username,
      password: password,
    };

    axios
      .post(environment.domain + "/user/login", loginInfo, {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        ToastAlert(
          "Welcome to Rent Scoop " + username?.toLowerCase(),
          "success",
        );
        navigate("/listings");
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }

  return (
    <>
      <div className="login-box">
        <img
          src="https://rentscoop.s3.amazonaws.com/branding/Rent-Scoop-First.svg"
          alt="Rent Scoop Logo"
          className="rent-scoop-logo-login"
        />
        <label className="login-label" htmlFor="username">
          Username:
        </label>
        <input
          className="custom-input"
          type="text"
          id="username"
          placeholder="Username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />

        <label className="login-label" htmlFor="password">
          Password:
        </label>
        <input
          className="custom-input"
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <button className="login-buttons" onClick={() => login()}>
          Login
        </button>

        {/* <p className="or-sign-in-with">or Sign In with</p>
        <button className="login-buttons google-login-button">Google</button> */}

        <div className="bottom-links">
          <a
            onClick={() => gotoForgotPassword()}
            style={{ textDecoration: "underline" }}
          >
            Forgot Password
          </a>
          <p>
            Not a member?{" "}
            <a
              onClick={() => gotoSignUp()}
              style={{ textDecoration: "underline" }}
            >
              Sign up
            </a>{" "}
            now
          </p>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
