/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
/*           3. Third Party           */
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AgGridReact } from "ag-grid-react";
/*        4. Rent Scoop Libraries     */
import { ToastAlert } from "../../../../Toast/index.jsx";
import { gridRef, columnRef, scatterChartRef } from "./Shared.jsx";
import * as Constant from "../../../../constants.js";
import {
  Card,
  Row,
  Col,
  Carousel,
  Navbar,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import {
  setDetailType,
  setSingleRental,
  setGridState,
} from "../../../../State/Actions/listingActions";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import axios from "axios";
import * as environment from "../../../../env";
import "../ManageListingsPage.css";

const StandardDetailView = (props) => {
  const [index, setIndex] = useState(0);
  const [user, setUser] = useState({});
  const [listings, setListings] = useState([]);

  useEffect(() => {
    setUser(props?.user);
    if (props?.user?._id) {
      axios
        .get(
          `${environment.domain}/user/getDetailedUser?user=${props?.user?._id}`,
          {
            withCredentials: true,
          },
        )
        .then((response) => {
          setUser(response.data.data);
          setListings(response.data.data?.listings || []);
          buildAndAttachMapMarkers(response.data.data?.listings || []);
        })
        .catch((error) => {
          console.error("Error fetching university details:", error);
        });
    }
  }, [props]);

  const onListingUpdate = (updatedListing) => {
    setListings((currentListings) =>
      currentListings.map((listing) =>
        listing._id === updatedListing._id ? updatedListing : listing,
      ),
    );
  };

  const onListingDelete = (idToDelete) => {
    setListings((currentListings) =>
      currentListings.filter((listing) => listing._id !== idToDelete),
    );
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const savedMapData = JSON.parse(localStorage.getItem("mapData"));
  const city =
    useSelector((state) => state.map.address) || savedMapData?.city || "Lowell";
  const state =
    useSelector((state) => state.map.state) || savedMapData?.state || "MA";
  const [filteredListings, setFilteredListings] = useState(null);
  const [currentSortOption, setCurrentSortOption] = useState("");
  const map = useSelector((state) => state.map.map);
  const dispatch = useDispatch();
  const savedGridState = useSelector((state) => state.listings.gridState);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    columnRef.current = params.columnApi;
    buildAndAttachMapMarkers(listings);
    if (savedGridState && gridRef.current && columnRef.current) {
      if (savedGridState.filterModel) {
        gridRef.current.setFilterModel(savedGridState.filterModel);
      }
      if (savedGridState.sortModel) {
        columnRef.current.applyColumnState({
          state: savedGridState.sortModel,
          defaultState: { sort: null },
        });
      }
    }
  };

  const onFilterChanged = () => {
    const filteredRows = [];
    gridRef.current.forEachNodeAfterFilter((node) => {
      filteredRows.push(node.data);
    });
    buildAndAttachMapMarkers(filteredRows);
    setFilteredListings(filteredRows);
    gridRef.current.redrawRows();
    const currentFilterModel = gridRef.current.getFilterModel();
    const allColumnState = columnRef.current.getColumnState();

    const currentSortModel = allColumnState
      .filter((colState) => colState.sort != null)
      .map((colState) => ({
        colId: colState.colId,
        sort: colState.sort,
        sortIndex: colState.sortIndex,
      }));
    dispatch(
      setGridState({
        sortModel: currentSortModel,
        filterModel: currentFilterModel,
      }),
    );
  };

  const onSortChanged = () => {
    const sortedData = [];
    gridRef.current.forEachNodeAfterFilterAndSort((node) => {
      sortedData.push(node.data);
    });
    setFilteredListings(sortedData);
    const currentFilterModel = gridRef.current.getFilterModel();
    const allColumnState = columnRef.current.getColumnState();
    const currentSortModel = allColumnState
      .filter((colState) => colState.sort != null)
      .map((colState) => ({
        colId: colState.colId,
        sort: colState.sort,
        sortIndex: colState.sortIndex,
      }));
    dispatch(
      setGridState({
        sortModel: currentSortModel,
        filterModel: currentFilterModel,
      }),
    );
  };

  const getSortOptionText = (eventKey) => {
    const sortOptions = {
      costLowHigh: "$ - $$$",
      costHighLow: "$$$ - $",
      bedCount: "Bed Count",
      bathCount: "Bath Count",
    };
    return sortOptions[eventKey] || "";
  };

  function buildAndAttachMapMarkers(listings) {
    const markers = document.getElementsByClassName("marker-element");
    while (markers.length > 0) {
      markers[0].parentNode.removeChild(markers[0]);
    }
    listings.forEach((rental) => {
      const markerElement = document.createElement("div");
      markerElement.className = "marker-element";
      markerElement.innerText = "$" + rental.monthRent;
      markerElement.style.backgroundColor = "#333399";
      markerElement.style.color = "white";
      markerElement.style.borderRadius = "10px";

      const addressParse = rental.address.split(",");
      const address = addressParse.slice(0, 2);

      const popupContent = `
  <div id="popup-content">
    <p style="margin-bottom: -5px;"><strong>${address.join(", ")}</strong></p>
    <p style="margin-bottom: -5px;"><strong>Rent:</strong> $${
      rental.monthRent
    }</p>
    <p style="margin-bottom: -5px;">$${(
      rental.monthRent / rental.numberOfBeds
    ).toFixed(0)}/bed</p>
    <p style="margin-bottom: -5px;">${
      rental.numberOfBeds
    } bd &nbsp&nbsp|&nbsp&nbsp${rental.numberOfBaths} ba</p>
    ${
      rental.utilitiesIncluded
        ? `<p style="margin-bottom: -5px;"><strong>Included Utilities:</strong> ${rental.utilitiesIncluded}</p>`
        : ""
    }
    ${
      rental.perks
        ? `<p style="margin-bottom: -5px;"><strong>Perks:</strong> ${rental.perks}</p>`
        : ""
    }
    ${
      rental.sqft
        ? `<p style="margin-bottom: -5px;"><strong>SqFt:</strong> ${rental.sqft}</p>`
        : ""
    }
  </div>
`;

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([rental.longitude, rental.latitude])
        .setPopup(popup)
        .addTo(map);

      markerElement.addEventListener("click", () => {
        dispatch(setDetailType("descriptive"));
        dispatch(setSingleRental(rental));
        marker.togglePopup();
      });
    });
  }

  const handleListingClick = (listing) => {
    dispatch(setDetailType("descriptive"));
    dispatch(setSingleRental(listing));
    map.flyTo({
      center: [listing.longitude, listing.latitude],
      zoom: 17,
    });
  };

  function useHandleSortSelect() {
    return (eventKey) => {
      if (gridRef && columnRef && columnRef.current) {
        let sortState;
        switch (eventKey) {
          case "costLowHigh":
            sortState = { colId: "monthRent", sort: "asc" };
            break;
          case "costHighLow":
            sortState = { colId: "monthRent", sort: "desc" };
            break;
          case "bedCount":
            sortState = { colId: "numberOfBeds", sort: "asc" };
            break;
          case "bathCount":
            sortState = { colId: "numberOfBaths", sort: "asc" };
            break;
          default:
            sortState = null;
            break;
        }

        columnRef.current.applyColumnState({
          state: sortState ? [sortState] : [],
          defaultState: { sort: null },
        });
      }
      setCurrentSortOption(eventKey);
    };
  }

  return (
    <>
      <div
        style={{
          display: "none",
        }}
      >
        <AgGridReact
          ref={gridRef}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          rowData={listings}
          columnDefs={useColumnDefs()}
          rowHeight={40}
          onRowClicked={(event) => {
            const record = event.data;
            map.flyTo({
              center: [record.longitude, record.latitude],
              zoom: 17,
            });
          }}
          floatingFiltersHeight={20}
          suppressCellFlash={true}
          onGridReady={onGridReady}
          rowClassRules={(params) => {
            if (params.node.rowIndex % 2 === 0) {
              return { color: Constant.EVEN_ROW_COLOR };
            } else {
              return { color: Constant.ODD_ROW_COLOR };
            }
          }}
        ></AgGridReact>
      </div>
      <div className="standard-detail-view">
        {/* First Row */}
        <Row>
          <Col>
            <h1
              style={{ fontWeight: "bold", fontSize: "28px", color: "white" }}
            >
              {user?.name}'s Rental Listings
            </h1>
          </Col>
        </Row>
        {/* Second Row */}
        <Row className="align-items-center" style={{ color: "white" }}>
          <Col xs={4}>
            {filteredListings ? filteredListings.length : listings.length}{" "}
            Results
          </Col>
          <Col xs={8} className="d-flex align-items-center justify-content-end">
            <span
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Sort By:
            </span>

            <NavDropdown
              title={getSortOptionText(currentSortOption)}
              onSelect={useHandleSortSelect()}
              style={{
                boxShadow: "none",
              }}
              className="p-0 bg-transparent border-0"
            >
              <NavDropdown.Item
                className="text-center"
                eventKey={"costLowHigh"}
                onSelect={() => setCurrentSortOption("costLowHigh")}
              >
                $ - $$$
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"costHighLow"}
                onSelect={() => setCurrentSortOption("costHighLow")}
              >
                $$$ - $
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"bedCount"}
                onSelect={() => setCurrentSortOption("bedCount")}
              >
                Bed Count
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-center"
                eventKey={"bathCount"}
                onSelect={() => setCurrentSortOption("bathCount")}
              >
                Bath Count
              </NavDropdown.Item>
            </NavDropdown>
          </Col>
        </Row>
        {/* Listings */}
        <Row>
          {(filteredListings ? filteredListings : listings).map(
            (listing, index) => (
              <Listing
                key={listing.id | index}
                user={user}
                listing={listing}
                handleListingClick={handleListingClick}
                onListingUpdate={onListingUpdate}
                onListingDelete={onListingDelete}
              />
            ),
          )}
        </Row>
      </div>
    </>
  );
};
export default StandardDetailView;

const defaultImage = [
  "https://th.bing.com/th/id/R.b7086134885bf7b52030aa4513d6a574?rik=WhI5peBNz5lcdg&riu=http%3a%2f%2fcliparts.co%2fcliparts%2f8TG%2fEr6%2f8TGEr6r7c.png&ehk=PYIpC1OwRjT43jJFNDf4Sm%2fZwQG8HlG3Nw6Dk0P6z0Q%3d&risl=&pid=ImgRaw&r=0",
];

const Listing = ({
  listing,
  user,
  handleListingClick,
  onListingUpdate,
  onListingDelete,
}) => {
  const imageToUse = listing.coverImage ? listing.coverImage : defaultImage;
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    // Check if the listing ID is in the user's savedListings to determine favorite state
    setFavorite(user?.savedListings?.includes(listing._id));
  }, [user, listing._id]);
  const handleToggleFavorite = async () => {
    if (favorite) {
      await handleRemoveFromSaved();
    } else {
      await handleAddToSaved();
    }
  };

  const handleAddToSaved = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/user/addSavedListing`,
        listing,
        { withCredentials: true },
      );
      setFavorite(true);
    } catch (error) {
      console.error("Error adding to saved listings:", error);
    }
  };

  const handleRemoveFromSaved = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/user/removeSavedListing`,
        listing,
        { withCredentials: true },
      );
      setFavorite(false);
    } catch (error) {
      console.error("Error removing from saved listings:", error);
    }
  };

  const handleActivate = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/listings/markListingActive`,
        { _id: listing._id },
        { withCredentials: true },
      );
      onListingUpdate(response.data.data); // assuming response.data contains the updated listing
      ToastAlert(
        "Listing was activated, and can be seen in search if approved.",
        "success",
      );
    } catch (error) {
      ToastAlert("Error activating listing", "error");
      console.error("Error marking listing as active:", error);
    }
  };

  const handleMarkFilled = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/listings/markListingFilled`,
        { _id: listing._id },
        { withCredentials: true },
      );
      onListingUpdate(response.data.data); // assuming response.data contains the updated listing
      ToastAlert(
        "Listing was marked as filled and will no longer appear in search.",
        "success",
      );
    } catch (error) {
      ToastAlert("Error filling listing", "error");
      console.error("Error marking listing as filled:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.post(
        `${environment.domain}/listings/deleteRentalListing`,
        { listing: listing },
        { withCredentials: true },
      );
      onListingDelete(listing._id); // pass the ID to the callback to delete from the array
      ToastAlert("Listing was deleted successfully.", "success");
    } catch (error) {
      ToastAlert("Error deleting listing.", "error");
      console.error("Error deleting listing:", error);
    }
  };
  return (
    <Col
      md={6}
      className="listing-col"
      style={{ padding: "0.5%", margin: "0" }}
    >
      <Card
        className="listing-card"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "275px",
          overflow: "hidden",
          margin: "0",
          padding: "0",
        }}
      >
        <div style={{ position: "relative", overflow: "hidden" }}>
          <img
            src={listing.coverImage || defaultImage}
            alt="Listing Cover"
            style={{ width: "100%", minHeight: "200px", objectFit: "cover" }}
          />
        </div>
        <Card.Body
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-10px",
            height: "35%",
          }}
        >
          <div style={{ flex: 1 }} onClick={() => handleListingClick(listing)}>
            <Card.Title style={{ marginBottom: "1px", fontWeight: "bold" }}>
              ${listing.monthRent}
            </Card.Title>
            <Card.Text style={{ marginBottom: "1px", fontSize: "smaller" }}>
              {listing.numberOfBeds} bds | {listing.numberOfBaths} ba |{" "}
              {listing.sqft} sqft
            </Card.Text>
            <Card.Text style={{ marginBottom: "1px", fontSize: "smaller" }}>
              {listing.address.split(",")[0]}
            </Card.Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {!listing?.isActive && (
              <button
                className="btn btn-primary btn-sm mb-2"
                onClick={handleActivate}
              >
                Activate
              </button>
            )}
            {listing?.isActive && (
              <button
                className="btn btn-secondary btn-sm mb-2"
                onClick={handleMarkFilled}
              >
                Mark Filled
              </button>
            )}
            <button className="btn btn-danger btn-sm" onClick={handleDelete}>
              Delete
            </button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

class RentCellRenderer extends React.Component {
  constructor(props) {
    super(props);
    props.api.selectAll();
  }

  render() {
    const cellStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor:
        this.props.node.rowIndex % 2 === 0
          ? Constant.EVEN_ROW_COLOR
          : Constant.ODD_ROW_COLOR,
    };

    const topLineStyle = {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(13, 110, 253)",
      marginBottom: "-20px",
    };

    const bottomLineStyle = {
      fontSize: "10px",
      fontStyle: "italic",
      marginTop: "1px",
    };
    return (
      <div style={cellStyle}>
        <div style={topLineStyle}>${this.props.value}</div>
        <div style={bottomLineStyle}>
          {" "}
          $
          {this.props.data.numberOfBeds
            ? Number(
                (this.props.value / this.props.data.numberOfBeds).toFixed(0),
              )
            : Number(this.props.value.toFixed(0))}
          /bed
        </div>
      </div>
    );
  }
}

function useColumnDefs() {
  const [columnDefs] = useState([
    {
      field: "monthRent",
      headerName: "Rent",
      headerClass: "centered-header",
      sortable: true,
      flex: 2,
      suppressMenu: true,
      filter: "agNumberColumnFilter",
      suppressMovable: true,
      cellRenderer: RentCellRenderer,
      cellStyle: (params) => ({
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBeds",
      headerName: "Beds",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      valueFormatter: (params) => {
        const studio = params.value;
        if (studio === 0) {
          return "Studio";
        } else {
          return params.value;
        }
      },
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBaths",
      headerName: "Baths",
      headerClass: "centered-header",
      filter: "agNumberColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "moveInDate",
      headerName: "Lease Start",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: false },
      suppressMenu: true,
      sortable: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
      valueGetter: (params) => {
        let date = params.data.moveInDate;
        if (date) {
          const year = new Date(date).getFullYear();
          const month = new Date(date).toLocaleDateString("en-US", {
            month: "short",
          });
          return `${month} ${year}`;
        } else {
          return null;
        }
      },
    },
    {
      field: "propertyType",
      headerName: "Type",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
  ]);
  return columnDefs;
}
