import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginType } from "../../State/Actions/loginActions";
import "../LoginPage.css";
import { ToastAlert } from "../../Toast";
import axios from "axios";
import * as environment from "../../env.js";

const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState("");
  const [code, setCode] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const dispatch = useDispatch();
  function gotoForgotPassword() {
    dispatch(setLoginType("forgotPassword"));
  }
  function gotoSignIn() {
    dispatch(setLoginType("signIn"));
  }
  function signUp() {
    const attributeList = [
      {
        Name: "email",
        Value: email,
      },
      {
        Name: "name",
        Value: username, // This should NOT be in email format.
      },
    ];

    // Use the 'username' as the main identifier, NOT the email.
    let userInfo = {
      username: username,
      password: password,
      email: email,
      accountType: accountType || "Other",
      contact: {
        email: email,
        displayEmail: false,
        displayPhone: false,
      },
      attributeList: attributeList,
    };
    axios
      .post(environment.domain + "/user/signUp", userInfo)
      .then((response) => {
        ToastAlert(
          "An email was sent to " +
            email +
            ". Please confirm your email to begin using your account",
          "info",
        );
        setIsVerify(true);
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }
  function resendVerification() {
    let usernameInfo = {
      username: username,
    };

    axios
      .post(environment.domain + "/user/resendCode", usernameInfo)
      .then((response) => {
        ToastAlert(
          "Confirmation code resent successfully. Check your email.",
          "info",
        );
        // navigate("/reset-password"); // Redirect to the password reset page
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }
  function verifyEmail() {
    let confirmationInfo = {
      username: username,
      confirmationCode: code,
    };

    axios
      .post(environment.domain + "/user/confirmSignUp", confirmationInfo)
      .then((response) => {
        ToastAlert("Account Created successfully!", "success");

        dispatch(setLoginType("signIn"));
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }
  return (
    <>
      {!isVerify ? (
        <div className="login-box">
          <img
            src="https://rentscoop.s3.amazonaws.com/branding/Rent-Scoop-First.svg"
            alt="Rent Scoop Logo"
            className="rent-scoop-logo-login"
          />
          <label className="login-label" htmlFor="username">
            Email:
          </label>
          <input
            className="custom-input"
            type="text"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <label className="login-label" htmlFor="username">
            Username:
          </label>
          <input
            className="custom-input"
            type="text"
            id="username"
            placeholder="Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />

          <label className="login-label" htmlFor="password">
            Password:
          </label>
          <input
            className="custom-input"
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <label className="login-label" htmlFor="accountType">
            Account Type:
          </label>
          <select
            className="custom-input"
            id="accountType"
            value={accountType}
            onChange={(event) => setAccountType(event.target.value)}
          >
            <option value="">Choose...</option>
            <option value="landlord">Landlord</option>
            <option value="student">Student</option>
            <option value="other">Other</option>
          </select>

          <button
            className="login-buttons register-button"
            onClick={() => signUp()}
          >
            Register
          </button>

          <div className="bottom-links">
            <p>
              Already have an account?{" "}
              <a
                onClick={() => gotoSignIn()}
                style={{ textDecoration: "underline" }}
              >
                Sign In
              </a>{" "}
              Here
            </p>
          </div>
        </div>
      ) : (
        <div className="login-box">
          <img
            src="https://amplify-amplifya7b38c5bab1a4-staging-222129-deployment.s3.amazonaws.com/Rent-Scoop-First.svg"
            alt="Rent Scoop Logo"
            className="rent-scoop-logo-login"
          />
          <label className="login-label" htmlFor="username">
            Code:
          </label>
          <input
            className="custom-input"
            type="text"
            id="code"
            placeholder="XXXXXX"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />

          <button
            className="login-buttons register-button"
            onClick={() => verifyEmail()}
          >
            Verify Account
          </button>

          <div className="bottom-links">
            <p
              onClick={() => resendVerification()}
              style={{ textDecoration: "underline" }}
            >
              Send me another code
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpPage;
