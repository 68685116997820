/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // CSS
import "ag-grid-community/styles/ag-theme-material.css"; // CSS
import "mapbox-gl/dist/mapbox-gl.css"; // CSS
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // CSS
import { Container, Row, Col, Card, Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, useLocation } from "react-router-dom";
/*        4. Rent Scoop Libraries     */
// import RentScatterChart from "./Components/ScatterChart.jsx";
import * as environment from "../env.js";
import "./UniversitiesPage.css"; // CSS
import TheNavbar from "../NavBar/Navbar.jsx";
import UniversityTypeAhead from "./Components/UniversityTypeAhead.jsx";
import Listings from "./Components/Listings.jsx";
import Records from "./Components/Records.jsx";
import SingleListing from "./Components/SingleListing.jsx";
import Roommates from "./Components/Roommates.jsx";
import Landlords from "./Components/Landlords.jsx";
import SingleLandlord from "./Components/SingleLandlord.jsx";
import SingleStudent from "./Components/SingleRoommate.jsx";
const UniversitiesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { universityID, listingID, landlordID, studentID } = useParams(); // Retrieve the universityID from the URL
  const baseUniversityPath =
    `/universities/${universityID}` ||
    "/universities/University%20of%20Massachusetts%20Lowell";
  const [university, setUniversity] = useState({});
  const [user, setUser] = useState({});
  const location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data.data);
      });

    // Use the universityID from the URL to fetch the correct university details
    if (universityID) {
      axios
        .get(
          `${environment.domain}/university/getUniversity?universityID=${universityID}`,
          {
            withCredentials: true,
          },
        )
        .then((response) => {
          setUniversity(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching university details:", error);
        });
    } else {
      let newUniversityID =
        user?.university?.name || "University%20of%20Massachusetts%20Lowell";
      navigate(`/universities/${newUniversityID}/listings`);
    }
  }, [universityID]);

  const handleUniversityChange = (newUniversityID) => {
    navigate(`/universities/${newUniversityID}/listings`); // Change the URL to the new universityID
  };

  let content;
  if (listingID) {
    content = (
      <Col xs={12} md={10} style={{ padding: "0", minHeight: "95vh" }}>
        <SingleListing />
      </Col>
    );
  } else if (landlordID) {
    content = (
      <Col
        xs={12}
        md={10}
        className="main-content"
        style={{ backgroundColor: "#3399FF", minHeight: "95vh" }}
      >
        <SingleLandlord />
      </Col>
    );
  } else if (studentID) {
    content = (
      <Col
        xs={12}
        md={10}
        className="main-content"
        style={{ backgroundColor: "#3399FF", minHeight: "95vh" }}
      >
        <SingleStudent />
      </Col>
    );
  } else if (location.pathname.includes("/listings")) {
    content = (
      <Col
        xs={12}
        md={10}
        className="main-content"
        style={{ backgroundColor: "#3399FF", minHeight: "95vh" }}
      >
        <Listings university={university} user={user} />
      </Col>
    );
  } else if (location.pathname.includes("/records")) {
    content = (
      <Col xs={12} md={10} style={{ padding: "0", minHeight: "95vh" }}>
        <Records university={university} />
      </Col>
    );
  } else if (location.pathname.includes("/landlords")) {
    content = (
      <Col
        xs={12}
        md={10}
        className="main-content"
        style={{ backgroundColor: "#3399FF", minHeight: "95vh" }}
      >
        <Landlords university={university} />
      </Col>
    );
  } else if (location.pathname.includes("/roommates")) {
    content = (
      <Col
        xs={12}
        md={10}
        className="main-content"
        style={{ backgroundColor: "#3399FF", minHeight: "95vh" }}
      >
        <Roommates university={university} />
      </Col>
    );
  } else {
    // Default content or redirect, etc.
    content = <p>You caught me</p>;
  }

  return (
    <>
      <Helmet>
        <title>
          {university.name
            ? university.name
            : "University of Massachusetts Lowell"}{" "}
          | Rent Scoop
        </title>
        <meta name="description" content={``} />
        <meta name="keywords" content={``} />
      </Helmet>
      <TheNavbar user={user} />
      <div className="search-box-container">
        <UniversityTypeAhead onUniversityChange={handleUniversityChange} />
      </div>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            md={2}
            className="sidebar"
            style={{ backgroundColor: "#333399", color: "white" }}
          >
            {/* Ensure your Navbar is correctly imported from react-bootstrap */}
            <Navbar
              collapseOnSelect
              expand="lg"
              className="flex-lg-column"
              style={{ backgroundColor: "#333399", color: "white" }}
            >
              <Navbar.Toggle
                aria-controls="sidebar-nav"
                className="align-toggle-right"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                <Navbar.Collapse id="sidebar-nav">
                  <Nav className="flex-lg-column">
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/records`}
                    >
                      Records
                    </Nav.Link>
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/listings`}
                    >
                      Listings
                    </Nav.Link>
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/landlords`}
                    >
                      Find Landlord
                    </Nav.Link>
                    <Nav.Link
                      className="nav-link-custom"
                      href={`${baseUniversityPath}/roommates`}
                    >
                      Find Roommate
                    </Nav.Link>
                    {/* <Nav.Link className="nav-link-custom" href="#scoop">The Scoop</Nav.Link> */}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </Col>
          {content}
        </Row>
      </Container>
    </>
  );
};
export default UniversitiesPage;
