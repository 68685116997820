/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import TheNavbar from "../NavBar/Navbar.jsx";
import axios from "axios";
import * as environment from "../env.js";

const ContactPage = () => {
  const [emailContent, setEmailContent] = useState("");
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = "Rent Scoop Feedback";

    window.location.href = `mailto:rentscoop.query@gmail.com?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(emailContent)}`;
  };
  return (
    <>
      <Helmet>
        <title>Contact Us | Rent Scoop</title>
        <meta
          name="description"
          content={`Rent Scoop is always open to user feedback and we would love to hear any suggestions our users have for the website!`}
        />
        <meta
          name="keywords"
          content={`rental community, rental questions, rental support, rental guidance`}
        />
      </Helmet>
      <TheNavbar user={user} />
      <div
        style={{
          backgroundColor: "#333399",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Container className="pt-5">
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <h2 className="text-center mb-4 font-weight-bold">
                Let us know what you think!
              </h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="emailContent">
                  <Form.Label>Write your email here:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    value={emailContent}
                    onChange={(e) => setEmailContent(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button type="submit">Submit</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactPage;
