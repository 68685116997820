/*           1. React Libraries       */
import React, { useState } from "react";
/*           2. Redux Libraries       */
import { useSelector } from "react-redux";
/*           3. Third Party           */
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AgGridReact } from "ag-grid-react";
/*        4. Rent Scoop Libraries     */
import { gridRef, columnRef, scatterChartRef } from "./Shared.jsx";
import * as Constant from "../../constants.js";

const RentAgGrid = () => {
  const rentData = useSelector((state) => state.grid.rentData);
  const map = useSelector((state) => state.map.map);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    columnRef.current = params.columnApi;
    buildAndAttachMapMarkers(rentData);
  };
  const onFilterChanged = () => {
    const filteredRows = [];
    gridRef.current.forEachNodeAfterFilter((node) => {
      filteredRows.push(node.data);
    });
    buildAndAttachMapMarkers(filteredRows);
    updateScatterChart(filteredRows);
    gridRef.current.redrawRows();
  };
  function updateScatterChart(filteredRows) {
    if (scatterChartRef.current) {
      let updatedData =
        filteredRows !== []
          ? formatRowData(filteredRows)
          : formatRowData(rentData);

      const scatterChartInstance = scatterChartRef.current;

      if (scatterChartInstance && scatterChartInstance.data) {
        scatterChartInstance.data = updatedData;

        scatterChartInstance.update("none");
      }
    }
  }

  function formatRowData(filteredData) {
    const dataPairs = filteredData.map((row) => {
      const rentPerBedroom = row.monthRent / row.numberOfBeds;
      return { x: rentPerBedroom.toFixed(0), y: row.numberOfBeds };
    });
    return buildFormatData(dataPairs);
  }
  function buildFormatData(dataPairs) {
    const chartDataFormatted = {
      labels: [],
      datasets: [
        {
          label: "Rows into scatter data",
          data: dataPairs,
          backgroundColor: "rgb(0 0 0 / 100%)",
          pointBorderColor: "rgba(255, 255, 255, 1)",
          pointBackgroundColor: "rgb(106 117 108 / 53%)",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 6,
          pointHitRadius: 10,
        },
      ],
    };

    return chartDataFormatted;
  }

  function buildAndAttachMapMarkers(rentData) {
    const markers = document.getElementsByClassName("marker-element");
    while (markers.length > 0) {
      markers[0].parentNode.removeChild(markers[0]);
    }

    rentData.forEach((rental) => {
      const markerElement = document.createElement("div");
      markerElement.className = "marker-element";
      markerElement.innerText = "$" + rental.monthRent;
      markerElement.style.backgroundColor = "#3399FF";
      markerElement.style.color = "white";
      markerElement.style.borderRadius = "10px";

      const addressParse = rental.address.split(",");
      const address = addressParse.slice(0, 2);

      const popupContent = `
  <div id="popup-content">
    <p style="margin-bottom: -5px;"><strong>${address.join(", ")}</strong></p>
    <p style="margin-bottom: -5px;"><strong>Rent:</strong> $${
      rental.monthRent
    }</p>
    <p style="margin-bottom: -5px;">$${(
      rental.monthRent / rental.numberOfBeds
    ).toFixed(0)}/bed</p>
    <p style="margin-bottom: -5px;">${
      rental.numberOfBeds
    } bd &nbsp&nbsp|&nbsp&nbsp${rental.numberOfBaths} ba</p>
    ${
      rental.utilitiesIncluded
        ? `<p style="margin-bottom: -5px;"><strong>Included Utilities:</strong> ${rental.utilitiesIncluded}</p>`
        : ""
    }
    ${
      rental.perks
        ? `<p style="margin-bottom: -5px;"><strong>Perks:</strong> ${rental.perks}</p>`
        : ""
    }
    ${
      rental.sqft
        ? `<p style="margin-bottom: -5px;"><strong>SqFt:</strong> ${rental.sqft}</p>`
        : ""
    }
  </div>
`;

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([rental.longitude, rental.latitude])
        .setPopup(popup)
        .addTo(map);

      markerElement.addEventListener("click", () => {
        marker.togglePopup();
      });
    });
  }
  return (
    <AgGridReact
      ref={gridRef}
      onFilterChanged={onFilterChanged}
      rowData={rentData}
      columnDefs={useColumnDefs()}
      rowHeight={40}
      onRowClicked={(event) => {
        const record = event.data;
        map.flyTo({
          center: [record.longitude, record.latitude],
          zoom: 17,
        });
      }}
      floatingFiltersHeight={20}
      suppressCellFlash={true}
      onGridReady={onGridReady}
      rowClassRules={(params) => {
        if (params.node.rowIndex % 2 === 0) {
          return { color: Constant.EVEN_ROW_COLOR };
        } else {
          return { color: Constant.ODD_ROW_COLOR };
        }
      }}
    ></AgGridReact>
  );
};
export default RentAgGrid;
class RentCellRenderer extends React.Component {
  constructor(props) {
    super(props);
    props.api.selectAll();
  }

  render() {
    const cellStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor:
        this.props.node.rowIndex % 2 === 0
          ? Constant.EVEN_ROW_COLOR
          : Constant.ODD_ROW_COLOR,
    };

    const topLineStyle = {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(13, 110, 253)",
      marginBottom: "-20px",
    };

    const bottomLineStyle = {
      fontSize: "10px",
      fontStyle: "italic",
      marginTop: "1px",
    };
    return (
      <div style={cellStyle}>
        <div style={topLineStyle}>${this.props.value}</div>
        <div style={bottomLineStyle}>
          {" "}
          $
          {this.props.data.numberOfBeds
            ? Number(
                (this.props.value / this.props.data.numberOfBeds).toFixed(0),
              )
            : Number(this.props.value.toFixed(0))}
          /bed
        </div>
      </div>
    );
  }
}

function useColumnDefs() {
  const [columnDefs] = useState([
    {
      field: "monthRent",
      headerName: "Rent",
      headerClass: "centered-header",
      sortable: true,
      flex: 2,
      suppressMenu: true,
      filter: "agNumberColumnFilter",
      suppressMovable: true,
      cellRenderer: RentCellRenderer,
      cellStyle: (params) => ({
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBeds",
      headerName: "Beds",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      valueFormatter: (params) => {
        const studio = params.value;
        if (studio === 0) {
          return "Studio";
        } else {
          return params.value;
        }
      },
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "numberOfBaths",
      headerName: "Baths",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
    {
      field: "moveInDate",
      headerName: "Lease Start",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: false },
      suppressMenu: true,
      sortable: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
      valueGetter: (params) => {
        let date = params.data.moveInDate;
        if (date) {
          const year = new Date(date).getFullYear();
          const month = new Date(date).toLocaleDateString("en-US", {
            month: "short",
          });
          return `${month} ${year}`;
        } else {
          return null;
        }
      },
    },
    {
      field: "propertyType",
      headerName: "Type",
      headerClass: "centered-header",
      filter: "agTextColumnFilter",
      suppressMovable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      flex: 2,
      cellStyle: (params) => ({
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "0%",
        backgroundColor:
          params.node.rowIndex % 2 === 0
            ? Constant.EVEN_ROW_COLOR
            : Constant.ODD_ROW_COLOR,
      }),
    },
  ]);
  return columnDefs;
}
