/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import axios from "axios";
import { Container, Form, Row, Button, Card } from "react-bootstrap";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import Select from "react-select";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
/*           3. Rent Scoop            */
import * as Constant from "../../../constants.js";
import { ToastAlert } from "../../../Toast/index.jsx";
import * as environment from "../../../env.js";
import UniversityTypeAhead from "../UniversityTypeAhead.jsx";
import { FaEdit } from "react-icons/fa";
import JSZip from "jszip";
import "./EditProfile.css"; // CSS

const EditProfile = (props) => {
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(null);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [likes, setLikes] = useState("");
  const [university, setUniversity] = useState("");
  const [appearOnUniversity, setAppearOnUniversity] = useState(false);
  const [email, setEmail] = useState("");
  const [displayEmail, setDisplayEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [displayPhone, setDisplayPhone] = useState(false);
  const [user, setUser] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Function to handle clicking the image to trigger file input
  const handleImageClick = () => {
    document.getElementById("profile-upload").click();
  };

  // Function to handle profile picture change and upload
  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const zip = new JSZip();
      zip.file(file.name, file);
      // Generate the zip file
      const content = await zip.generateAsync({ type: "blob" });

      // Prepare FormData with the zipped file
      const formData = new FormData();
      formData.append("profilePic", content, `${file.name}.zip`);

      // Send the request to your backend
      axios
        .post(environment.domain + "/user/uploadPfp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // If you're using sessions
        })
        .then((response) => {
          ToastAlert("Profile Picture Updated Successfully!", "success");
          setUser(response.data.data);
          // Update local state/UI as needed
        })
        .catch((error) => {
          console.error("Error uploading profile picture:", error);
          // Handle error
        });
    }
  };

  useEffect(() => {
    setUser(props?.user);
    setName(props?.user.name || "");
    setBio(props?.user?.bio || "");
    setLikes(props?.user?.likes || "");
    setUniversity(props?.user?.university?.name || "");
    setAppearOnUniversity(props?.user?.appearOnUniversity || false);
    setEmail(props?.user?.contact?.email || "");
    setDisplayEmail(props?.user?.contact?.displayEmail || false);
    setPhone(props?.user?.contact?.phone || "");
    setDisplayPhone(props?.user?.contact?.displayPhone || false);
  }, [props]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let user = {
      name: name,
      contact: {},
    };
    if (bio) {
      user.bio = bio;
    }
    if (likes) {
      user.likes = likes;
    }
    if (university) {
      user.university = university._id;
    }
    if (appearOnUniversity) {
      user.appearOnUniversity = appearOnUniversity;
    }
    if (email) {
      user.contact.email = email;
    }
    if (displayEmail) {
      user.contact.displayEmail = displayEmail;
    }
    if (phone) {
      user.contact.phone = phone;
    }
    if (displayPhone) {
      user.contact.displayPhone = displayPhone;
    }
    if (validate()) {
      axios
        .post(environment.domain + "/user/editUser", user, {
          withCredentials: true, // needed for sessions
        })
        .then((response) => {
          ToastAlert("Profile Updated Successfully!", "success");
          setUser(response.data.data);
          setIsEditing(false);
        })
        .catch((error) => {
          console.error(error);
          ToastAlert(
            "There was an error updating you profile try again!",
            "info",
          );
        });
    }
  };

  const cancelForm = () => {
    setIsEditing(false);
  };
  function validate() {
    const errors = {};
    if (appearOnUniversity && !university) {
      errors.university =
        "To search for roommates you must be connected to a university!";
      ToastAlert(errors.university, "info");
    }
    if (displayEmail && !email) {
      errors.email = "Please and enter the email you wish to display";
      ToastAlert(errors.email, "info");
    }
    if (displayPhone && !phone) {
      errors.phone = "Please and enter the email you wish to display";
      ToastAlert(errors.phone, "info");
    }
    return Object.keys(errors).length === 0;
  }
  const handleUniversitySelection = (university) => {
    setUniversity(university);
  };
  return (
    <>
      <div className="form-page">
        <Container className="d-flex justify-content-center mt-2">
          <Card
            style={{
              borderRadius: "10px",
              backgroundColor: "#3399FF",
              border: "0px",
            }}
          >
            <Card.Body>
              <div className="profile-container d-flex flex-column align-items-center">
                <div
                  className="position-relative image-hover-wrapper"
                  onClick={handleImageClick}
                >
                  {" "}
                  {/* Attach onClick event handler here */}
                  <img
                    src={
                      user?.pfp ||
                      "https://yourteachingmentor.com/wp-content/uploads/2020/12/istockphoto-1223671392-612x612-1.jpg"
                    }
                    alt="Profile"
                    className="profile-image"
                  />
                  <div className="edit-icon-overlay">
                    <FaEdit className="edit-icon" />
                  </div>
                  <input
                    type="file"
                    id="profile-upload"
                    style={{ display: "none" }}
                    onChange={handleProfilePictureChange} // Attach onChange event handler to input
                    accept="image/*"
                  />
                </div>
                {!isEditing && (
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleEditClick}
                  >
                    Edit Profile
                  </button>
                )}
              </div>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  {/* City State */}
                  <Row>
                    <h5
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        marginLeft: "-20px",
                      }}
                    >
                      Account Info:
                    </h5>
                    <Form.Group controlId="formName" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Display Name: &nbsp;
                      </Form.Label>
                      <Form.Control
                        name="name"
                        placeholder={user.name || "Name"}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  {/* Zip Code & Month Rent*/}
                  <Row>
                    <Form.Group controlId="formBio" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Bio: &nbsp;
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="bio"
                        placeholder={
                          user.bio ||
                          "Bio. Here you can share some socials to help others reach you!"
                        }
                        value={bio}
                        onChange={(event) => setBio(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formLikes" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Likes: &nbsp;
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="likes"
                        placeholder={
                          user.likes ||
                          "Tell potential roommates about the things you like!"
                        }
                        value={likes}
                        onChange={(event) => setLikes(event.target.value)}
                      />
                    </Form.Group>
                    {/* Monthly Rent */}
                    <Form.Group controlId="formSqft" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        University (if affiliated with one): &nbsp;
                      </Form.Label>

                      <UniversityTypeAhead
                        handleUniversitySelection={handleUniversitySelection}
                        user={user}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="formSearchingForRoommate"
                      className="d-flex align-items-center"
                    >
                      <Form.Label
                        style={{
                          color: "white",
                          marginBottom: 0,
                          fontWeight: "bold",
                        }}
                      >
                        {user.accountType === "landlord"
                          ? "Appear on University Page"
                          : "Searching for Roommate"}
                        : &nbsp;
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name={
                          user.accountType === "landlord"
                            ? "appearOnUniversityPage"
                            : "searchingForRoommate"
                        }
                        id={
                          user.accountType === "landlord"
                            ? "appearOnUniversityPage"
                            : "searchingForRoommate"
                        }
                        checked={appearOnUniversity} // This binds the checkbox to your component's state or another state variable if needed for "Appear on University Page"
                        onChange={(e) =>
                          setAppearOnUniversity(e.target.checked)
                        } // This updates the state when the checkbox is toggled. Consider using a different state variable for "Appear on University Page" if necessary
                        style={{
                          marginLeft: "10px",
                          marginTop: "0",
                          marginBottom: "0",
                        }}
                      />
                    </Form.Group>

                    <h5
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        marginLeft: "-20px",
                      }}
                    >
                      Contact:
                    </h5>
                  </Row>
                  {/* Move In & Move Out */}
                  <Row>
                    <Form.Group controlId="email" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Email (note this will not effect login email): &nbsp;
                      </Form.Label>
                      <Form.Control
                        name="email"
                        placeholder={user?.contact?.email || "email"}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Label
                        style={{
                          color: "white",
                          marginBottom: 0,
                          fontWeight: "bold",
                        }}
                      >
                        Display Email: &nbsp;
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="displayEmail"
                        id="displayEmail"
                        checked={displayEmail} // Use the displayEmail state
                        onChange={(e) => setDisplayEmail(e.target.checked)} // Update displayEmail state
                        style={{
                          marginLeft: "10px",
                          marginTop: "0",
                          marginBottom: "0",
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="formPhone"
                      className="col-md-12"
                      style={{ marginTop: "1rem" }}
                    >
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Phone Number: &nbsp;
                      </Form.Label>
                      <Form.Control
                        type="text" // Specify the type (optional, but recommended for clarity)
                        name="phone"
                        placeholder="Phone number" // Updated placeholder to "Phone number"
                        value={phone} // Use the phone state
                        onChange={(e) => setPhone(e.target.value)} // Update phone state
                      />
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Display Phone Number: &nbsp;
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="displayPhone"
                        id="displayPhone"
                        checked={displayPhone} // Use the displayPhone state
                        onChange={(e) => setDisplayPhone(e.target.checked)} // Update displayPhone state
                        style={{
                          marginLeft: "10px",
                          marginTop: "0",
                          marginBottom: "0",
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <div className="d-flex justify-content-end align-items-end w-100">
                      <Button
                        variant="danger"
                        className="mt-3"
                        onClick={() => cancelForm()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-3 ms-2" // Use ms-2 or ml-2 for margin-left if necessary
                      >
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Form>
              ) : (
                <Form style={{ color: "white" }}>
                  {/* City State */}
                  <Row>
                    <h5
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        marginLeft: "-20px",
                      }}
                    >
                      Account Info:
                    </h5>
                    <Form.Group controlId="formName" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Display Name: &nbsp;
                      </Form.Label>
                      <span>{user?.name}</span>
                    </Form.Group>
                  </Row>
                  {/* Zip Code & Month Rent*/}
                  <Row>
                    <Form.Group controlId="formBio" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Bio: &nbsp;
                      </Form.Label>
                      <span>{user?.bio}</span>
                    </Form.Group>
                    <Form.Group controlId="formLikes" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Likes: &nbsp;
                      </Form.Label>
                      <span>{user?.likes}</span>
                    </Form.Group>
                    {/* Monthly Rent */}
                    <Form.Group controlId="formSqft" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        University: &nbsp;
                      </Form.Label>
                      <span>{user?.university?.name}</span>
                    </Form.Group>
                    <Form.Group
                      controlId="formAppearingOnUniversityPage"
                      className="d-flex align-items-center"
                    >
                      <Form.Label
                        style={{
                          color: "white",
                          marginBottom: 0,
                          fontWeight: "bold",
                        }}
                      >
                        {user.accountType === "landlord"
                          ? "Appear on University Page"
                          : "Searching for Roommate"}
                        : &nbsp;
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name={
                          user.accountType === "landlord"
                            ? "appearOnUniversityPage"
                            : "searchingForRoommate"
                        }
                        id={
                          user.accountType === "landlord"
                            ? "appearOnUniversityPage"
                            : "searchingForRoommate"
                        }
                        checked={user?.appearOnUniversity}
                        disabled // Keep the checkbox disabled or make it dynamic based on your requirements
                        style={{
                          marginLeft: "10px",
                          marginTop: "0",
                          marginBottom: "0",
                        }}
                      />
                    </Form.Group>

                    <h5
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        marginLeft: "-20px",
                      }}
                    >
                      Contact:
                    </h5>
                  </Row>
                  {/* Move In & Move Out */}
                  <Row>
                    <Form.Group controlId="formCity" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Email: &nbsp;
                      </Form.Label>
                      <span>{user?.contact?.email}</span>
                    </Form.Group>
                    <Form.Group
                      controlId="formSearchingForRoommate"
                      className="d-flex align-items-center"
                    >
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Display Email: &nbsp;
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="displayEmail"
                        id="displayEmail"
                        checked={user?.contact?.displayEmail} // Reflect the current value
                        disabled // Keep the checkbox disabled
                        style={{
                          marginLeft: "10px",
                          marginTop: "0",
                          marginBottom: "0",
                        }} // Adjust marginLeft as needed to space it from the label
                      />
                    </Form.Group>
                    <Form.Group controlId="formCity" className="col-md-12">
                      <Form.Label
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Phone Number: &nbsp;
                      </Form.Label>
                      <span>{user?.contact?.phone}</span>
                    </Form.Group>
                    <Form.Group
                      controlId="formSearchingForRoommate"
                      className="d-flex align-items-center"
                    >
                      <Form.Label
                        style={{
                          color: "white",
                          marginBottom: 0,
                          fontWeight: "bold",
                        }}
                      >
                        Display Phone Number: &nbsp;
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="displayPhone"
                        id="displayPhone"
                        checked={user?.contact?.displayPhone} // Reflect the current value
                        disabled // Keep the checkbox disabled
                        style={{
                          marginLeft: "10px",
                          marginTop: "0",
                          marginBottom: "0",
                        }} // Adjust marginLeft as needed to space it from the label
                      />
                    </Form.Group>
                  </Row>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default EditProfile;
