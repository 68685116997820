/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // CSS
import "ag-grid-community/styles/ag-theme-material.css"; // CSS
import "mapbox-gl/dist/mapbox-gl.css"; // CSS
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // CSS
import { Container, Row, Col, Card, Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import * as environment from "../../env.js";
import { setPopupCarousel } from "../../State/Actions/universityActions";
import ListingImagesCarousel from "./ListingsComponents/ListingImagesCarousel.jsx";
import MapComponent from "./ListingsComponents/Map.jsx";
const SingleListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { universityID, listingID } = useParams();
  const [singleListing, setSingleListing] = useState({
    address: "Loading...,",
  });
  useEffect(() => {
    axios
      .get(`${environment.domain}/listings/getSingleListing?_id=${listingID}`, {
        withCredentials: true,
      })
      .then((response) => {
        setSingleListing(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching university details:", error);
      });
  }, []);

  const defaultImage = [
    "https://th.bing.com/th/id/R.b7086134885bf7b52030aa4513d6a574?rik=WhI5peBNz5lcdg&riu=http%3a%2f%2fcliparts.co%2fcliparts%2f8TG%2fEr6%2f8TGEr6r7c.png&ehk=PYIpC1OwRjT43jJFNDf4Sm%2fZwQG8HlG3Nw6Dk0P6z0Q%3d&risl=&pid=ImgRaw&r=0",
  ];
  const defaultImages = [
    "https://th.bing.com/th/id/R.b7086134885bf7b52030aa4513d6a574?rik=WhI5peBNz5lcdg&riu=http%3a%2f%2fcliparts.co%2fcliparts%2f8TG%2fEr6%2f8TGEr6r7c.png&ehk=PYIpC1OwRjT43jJFNDf4Sm%2fZwQG8HlG3Nw6Dk0P6z0Q%3d&risl=&pid=ImgRaw&r=0",
  ];

  const handleListingClick = () => {
    navigate(`/universities/${universityID}/listings/`);
  };

  const handleImageClick = () => {
    dispatch(setPopupCarousel("active"));
  };

  const imageToUse = singleListing.coverImage
    ? singleListing.coverImage
    : defaultImage;
  if (!singleListing) {
    return <div>Loading...</div>; // Or return null; if you don't want to show any loading indicator
  }
  return (
    <>
      <ListingImagesCarousel singleListing={singleListing} />
      <Container fluid>
        <Row>
          <Col
            xs={12}
            md={6}
            className="sidebar"
            style={{ backgroundColor: "#333399", color: "white", padding: "0" }}
          >
            <MapComponent listing={singleListing} />
          </Col>
          <Col xs={12} md={6} style={{ padding: "0" }}>
            <div className="descriptive-detail-view" style={{ width: "100%" }}>
              <Card style={{ cursor: "pointer", height: "100%" }}>
                <Card.Header
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {singleListing?.address.split(",").slice(0, 1).join(", ")}
                  </span>
                  <FaTimes onClick={handleListingClick} />
                </Card.Header>
                <div
                  style={{
                    position: "relative",
                    maxHeight: "60vh",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={imageToUse}
                    alt="Listing Cover"
                    onClick={handleImageClick}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "10px",
                      backgroundColor: "#ffffff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    }}
                    onClick={handleImageClick}
                  >
                    View All Photos
                  </div>
                </div>
                <Card.Body style={{ height: "50%" }}>
                  <Card.Text>
                    <strong>Description:</strong> {singleListing.comments}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-1px" }}>
                    <strong>Monthly Rent: ${singleListing?.monthRent}</strong>
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-20px" }}>
                    <strong>
                      Per Bedroom: $
                      {singleListing?.monthRent / singleListing?.numberOfBeds}
                    </strong>
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-20px" }}>
                    <strong>Beds:</strong> {singleListing?.numberOfBeds}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-20px" }}>
                    <strong>Baths:</strong> {singleListing?.numberOfBaths}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-20px" }}>
                    <strong>Included Utilities:</strong>{" "}
                    {singleListing?.utilitiesIncluded}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-20px" }}>
                    <strong>Perks:</strong> {singleListing?.perks}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-20px" }}>
                    <strong>Square Feet:</strong> {singleListing?.sqft}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "50px" }}>
                    <strong>Listed By:</strong> {singleListing?.landlord?.name}
                  </Card.Text>
                  {(singleListing?.landlord?.contact?.displayEmail &&
                    singleListing?.landlord?.contact?.email) ||
                  (singleListing?.landlord?.contact?.displayPhone &&
                    singleListing?.landlord?.contact?.phone) ? (
                    <>
                      <Card.Text style={{ marginTop: "-20px" }}>
                        <strong>Contact:</strong>
                      </Card.Text>
                      {singleListing?.landlord?.contact?.displayEmail &&
                        singleListing?.landlord?.contact?.email && (
                          <Card.Text
                            style={{ marginTop: "-20px", marginLeft: "10px" }}
                          >
                            <strong>Email: </strong>{" "}
                            {singleListing.landlord.contact.email}
                          </Card.Text>
                        )}
                      {singleListing?.landlord?.contact?.displayPhone &&
                        singleListing?.landlord?.contact?.phone && (
                          <Card.Text
                            style={{ marginTop: "-20px", marginLeft: "10px" }}
                          >
                            <strong>Phone: </strong>{" "}
                            {singleListing.landlord.contact.phone}
                          </Card.Text>
                        )}
                    </>
                  ) : null}
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SingleListing;
