import { createSlice, combineReducers } from "@reduxjs/toolkit";

const popupCarouselSlice = createSlice({
  name: "popupCarousel",
  initialState: "disabled",
  reducers: {
    setPopupCarousel(state, action) {
      return action.payload;
    },
  },
});

const popupImagesSlice = createSlice({
  name: "popupImages",
  initialState: [],
  reducers: {
    setPopupImages(state, action) {
      return action.payload;
    },
  },
});

// Exports
export const universityReducers = combineReducers({
  popupCarousel: popupCarouselSlice.reducer,
  popupImages: popupImagesSlice.reducer,
});

export const { setPopupCarousel: setPopupCarousel } =
  popupCarouselSlice.actions;
export const { setPopupImages: setPopupImages } = popupImagesSlice.actions;
