/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import axios from "axios";
import JSZip from "jszip";
import { Container, Form, Row, Button, Card } from "react-bootstrap";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import Select from "react-select";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
/*           3. Rent Scoop            */
import * as Constant from "../constants.js";
import { ToastAlert } from "../Toast";
import * as environment from "../env.js";
import TheNavbar from "../NavBar/Navbar.jsx";
import "./CreateListing.css";
import UniversityTypeAhead from "./Components/UniversityTypeAhead.jsx";

const CreateListing = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [monthRent, setMonthRent] = useState(null);
  const [moveInDate, setMoveInDate] = useState(null);
  const [moveOutDate, setMoveOutDate] = useState(null);
  const [propertyType, setPropertyType] = useState("");
  const [numberOfBeds, setNumberOfBeds] = useState(null);
  const [numberOfBaths, setNumberOfBaths] = useState(null);
  const [comments, setComments] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedPerks, setSelectedPerks] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [sqft, setSqft] = useState("");
  const [university, setUniversity] = useState({});
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
        if (!response?.data?.data?.username) {
          navigate("/login");
        }
      });
  }, []);
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const handlePerkSelection = (selectedPerks) => {
    setSelectedPerks(selectedPerks);
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...files]);
  };

  useEffect(() => {
    mapboxgl.accessToken = environment.mapboxgl;
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Enter an address",
      inputValue: "",
      input: "#geocoder",
      types: "address",
      countries: "us",
    });

    geocoder.on("result", (result) => {
      setAddress(result.result.place_name);
      setLatitude(parseFloat(result.result.center[1]));
      setLongitude(parseFloat(result.result.center[0]));
      if (/^place\.\d+$/.test(result.result.id)) {
        setCity(result.result.text);
      }
      result.result.context?.forEach((context) => {
        if (/^place\.\d+$/.test(context.id)) {
          setCity(context.text);
        }
      });
      result.result.context?.forEach((context) => {
        if (/^postcode\.\d+$/.test(context.id)) {
          setZip(context.text);
        }
      });
      result.result.context?.forEach((context) => {
        if (/^region\.\d+$/.test(context.id)) {
          setState(context.short_code.substring(3));
        }
      });
    });
    geocoder.on("clear", () => {
      setAddress("");
      setCity("");
      setZip("");
      setState("");
    });
    geocoder.addTo("#geocoder");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Initialize FormData
    const rental = new FormData();

    // Append text fields to formData
    rental.append("address", address);
    rental.append("city", city);
    rental.append("state", state);
    rental.append("latitude", latitude.toString());
    rental.append("longitude", longitude.toString());
    rental.append("zip", zip);
    rental.append("monthRent", monthRent.toString());
    rental.append("moveInDate", moveInDate);
    rental.append("propertyType", propertyType);
    rental.append("numberOfBeds", numberOfBeds.toString());
    rental.append("numberOfBaths", numberOfBaths.toString());
    if (university) {
      rental.append("universityID", university?._id);
      rental.append("universityName", university?.name);
    }
    rental.append(
      "utilitiesIncluded",
      selectedOptions.map((elem) => elem.value).join(", "),
    );
    rental.append("perks", selectedPerks.map((elem) => elem.value).join(", "));
    rental.append("comments", comments);
    rental.append("sqft", sqft);
    rental.append("source", "userSubmitted");
    if (moveOutDate) {
      rental.append("moveOutDate", moveOutDate);
    }

    // Zip the files and append the zip to formData
    const zipp = new JSZip();
    // Loop through the selectedFiles and add them to the zip
    selectedFiles.forEach((file, index) => {
      zipp.file(
        `${address.replace(/\s+/g, "_")}_${index + 1}.${file.name.split(".").pop()}`,
        file,
      );
    });
    const images = await zipp.generateAsync({ type: "blob" });
    rental.append("images", images, "images.zip");

    if (moveOutDate) {
      rental.moveOutDate = moveOutDate;
    }

    if (validate()) {
      axios
        .post(environment.domain + "/addRental/createRentalListing", rental, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => {
          ToastAlert(
            "Thank you for submitting a listing! You submission will be reviewed shortly.",
            "success",
          );
          setAddress("");
          setCity("");
          setState("");
          setZip("");
          setMonthRent("");
          setMoveInDate("");
          setMoveOutDate("");
          setPropertyType("");
          setNumberOfBeds("");
          setNumberOfBaths("");
          setComments("");
          setSqft("");
          setSelectedOptions([]);
          setSelectedPerks([]);
          setSelectedFiles([]);
        })
        .catch((error) => {
          console.error(error);
          ToastAlert(
            "There was an error submitting your form try again!",
            "info",
          );
        });
    }
  };

  const cancelForm = () => {
    setAddress("");
    setCity("");
    setState("");
    setZip("");
    setMonthRent("");
    setMoveInDate("");
    setMoveOutDate("");
    setPropertyType("");
    setNumberOfBeds("");
    setNumberOfBaths("");
    setComments("");
    setSqft("");
    setSelectedOptions([]);
    setSelectedPerks([]);
    setSelectedFiles([]);
  };
  function validate() {
    const errors = {};
    if (!address.trim()) {
      errors.name =
        "Adress is required: make sure to select your address from the dropdown menu.";
      ToastAlert(errors.name, "info");
    }
    if (!city.trim()) {
      errors.city = "City is required";
      ToastAlert(errors.city, "info");
    }
    if (!state.trim() || state === "Choose..." || state === "") {
      errors.state = "State is required";
      ToastAlert(errors.state, "info");
    }
    if (!zip.trim()) {
      errors.zip = "Zip is required";
      ToastAlert(errors.zip, "info");
    } else if (isNaN(zip)) {
      errors.zip = "Zip should be a number";
      ToastAlert(errors.zip, "info");
    }
    if (isNaN(parseInt(monthRent))) {
      errors.monthRent = "Monthly rent should be a number";
      ToastAlert(errors.monthRent, "info");
    } else if (parseInt(monthRent) < 200) {
      errors.monthRent =
        "Rent amount is too low, reach out if you feel we've made a mistake";
      ToastAlert(errors.monthRent, "info");
    }
    if (!moveInDate) {
      errors.moveIn = "Move in date is required";
      ToastAlert(errors.moveIn, "info");
    }
    if (moveOutDate) {
      if (moveInDate > moveOutDate) {
        errors.moveInDate = "Move out date should be after move in date";
        ToastAlert(errors.moveInDate, "info");
      }
    }
    if (propertyType === "Choose..." || propertyType === "") {
      errors.propertyType = "Property type is required";
      ToastAlert(errors.propertyType, "info");
    }
    if (
      numberOfBeds === "Choose..." ||
      isNaN(numberOfBeds) ||
      numberOfBeds === ""
    ) {
      errors.numberOfBeds = "Number of beds is required";
      ToastAlert(errors.numberOfBeds, "info");
    }
    if (
      numberOfBaths === "Choose..." ||
      isNaN(numberOfBaths) ||
      numberOfBaths === "" ||
      !numberOfBaths
    ) {
      errors.numberOfBaths = "Number of baths is required";
      ToastAlert(errors.numberOfBaths, "info");
    }
    return Object.keys(errors).length === 0;
  }
  const handleUniversitySelection = (university) => {
    setUniversity(university);
  };
  return (
    <>
      <Helmet>
        <title>Create Listing | Rent Scoop</title>
        <meta
          name="description"
          content={`We appreciate you adding to our crowdsoured dataset bringing transparency to the rental market! On this page you can now list any vacancies you may have!`}
        />
        <meta
          name="keywords"
          content={`create listing, create a listing, create rent data, share rent data, share rent information`}
        />
      </Helmet>
      <TheNavbar user={user} />
      <div className="form-page" style={{ backgroundColor: "#D9D9D9" }}>
        <Container className="d-flex justify-content-center mt-2">
          <Card style={{ borderRadius: "10px", backgroundColor: "#3399FF" }}>
            <Card.Title
              style={{
                backgroundColor: "#333399",
                color: "white",
                padding: "20px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <span> Create a Listing!</span>
            </Card.Title>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {/* Address */}
                <Row>
                  <Form.Group id="geocoder" className="col-md-6">
                    <Form.Label style={{ color: "white" }}>
                      Address *
                    </Form.Label>
                  </Form.Group>
                  <Form.Group controlId="dtMoveIn" className="col-md-6">
                    <Form.Label style={{ color: "white" }}>
                      Start Date *
                    </Form.Label>
                    <DatePicker
                      name="dtMoveIn"
                      selected={moveInDate}
                      onChange={(date) => setMoveInDate(date)}
                      showMonthYearPicker
                      showFullMonthYearPicker="true"
                      placeholderText="MM/DD/YYYY"
                    />
                  </Form.Group>
                </Row>
                {/* City State */}
                <Row>
                  <Form.Group controlId="formCity" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>City *</Form.Label>
                    <Form.Control
                      name="city"
                      placeholder="City"
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="inputState" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>State *</Form.Label>
                    <Form.Control
                      as="select"
                      name="state"
                      value={state}
                      onChange={(event) => setState(event.target.value)}
                    >
                      <option value="">Choose...</option>
                      {Constant.statesOptions.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Row>
                {/* Zip Code & Month Rent*/}
                <Row>
                  <Form.Group controlId="formZip" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>Zip *</Form.Label>
                    <Form.Control
                      name="zip"
                      placeholder="00000"
                      value={zip}
                      onChange={(event) => setZip(event.target.value)}
                    />
                  </Form.Group>
                  {/* Monthly Rent */}
                  <Form.Group controlId="monthRent" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      Monthly Rent *
                    </Form.Label>
                    <Form.Control
                      placeholder="1000"
                      name="monthRent"
                      value={monthRent}
                      onChange={(event) => setMonthRent(event.target.value)}
                    />
                  </Form.Group>
                </Row>
                {/* Move In & Move Out */}
                {/* <Row>
                  
                  {/* Move Out */}
                {/* <Form.Group controlId="dtMoveOut" className="col-md-6">
                    <Form.Label style={{color:'white'}}>Move Out</Form.Label>
                    <DatePicker
                      name="dtMoveOut"
                      selected={moveOutDate}
                      onChange={(date) => setMoveOutDate(date)}
                      showMonthYearPicker
                      showFullMonthYearPicker="true"
                      placeholderText="MM/DD/YYYY"
                    />
                  </Form.Group> 
                </Row> */}
                {/* Property Type */}
                <Row>
                  <Form.Group controlId="propType" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      Property Type *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="propertyType"
                      value={propertyType}
                      onChange={(event) => setPropertyType(event.target.value)}
                    >
                      <option value="">Choose...</option>
                      {Constant.propertyTypesOptions.map((property) => (
                        <option key={property.key} value={property.value}>
                          {property.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {/* Bed Number & Bath Number*/}
                  <Form.Group className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      Included Utilities
                    </Form.Label>
                    <Select
                      options={Constant.utilityOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={handleChange}
                      value={selectedOptions}
                      placeholder="Select options"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  {/* Bed Number */}
                  <Form.Group controlId="bedNum" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      No. of Beds *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="numberOfBedss"
                      value={numberOfBeds}
                      onChange={(event) =>
                        setNumberOfBeds(parseInt(event.target.value))
                      }
                    >
                      <option value="">Choose...</option>
                      {Constant.bedNumsOptions.map((bed) => (
                        <option key={bed.key} value={bed.value}>
                          {bed.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {/* Bath Number */}
                  <Form.Group controlId="bathNum" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      No. of Bathrooms *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="numberOfBaths"
                      value={numberOfBaths}
                      onChange={(event) =>
                        setNumberOfBaths(parseFloat(event.target.value))
                      }
                    >
                      <option value="">Choose...</option>
                      {Constant.bathNumsOptions.map((bath) => (
                        <option key={bath.key} value={bath.value}>
                          {bath.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-md-12">
                    <Form.Label style={{ color: "white" }}>Perks</Form.Label>
                    <Select
                      options={Constant.perksOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={handlePerkSelection}
                      value={selectedPerks}
                      placeholder="Select options"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                  <Form.Group controlId="formSqft" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      Square Feet
                    </Form.Label>
                    <Form.Control
                      name="sqft"
                      placeholder="Sqft"
                      value={sqft}
                      onChange={(event) => setSqft(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSqft" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      University (if affiliated with one)
                    </Form.Label>

                    <UniversityTypeAhead
                      handleUniversitySelection={handleUniversitySelection}
                    />
                  </Form.Group>
                  <Form.Group controlId="formComments" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>
                      Listing Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comments"
                      placeholder="Enter description"
                      value={comments}
                      onChange={(event) => setComments(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formImages" className="col-md-12">
                    <Form.Label style={{ color: "white" }}>Images</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    variant="danger"
                    className="mt-3 col-3"
                    onClick={() => cancelForm()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 ms-3 col-3"
                  >
                    Submit
                  </Button>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default CreateListing;
