import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { setPopupCarousel } from "../../../../State/Actions/listingActions";
import { FaTimes } from "react-icons/fa";

const ListingImagesCarousel = () => {
  const dispatch = useDispatch();
  const singleListing = useSelector((state) => state.listings.singleRental);
  const popupCarousel = useSelector((state) => state.listings.popupCarousel);
  const defaultImages = [
    "https://th.bing.com/th/id/R.b7086134885bf7b52030aa4513d6a574?rik=WhI5peBNz5lcdg&riu=http%3a%2f%2fcliparts.co%2fcliparts%2f8TG%2fEr6%2f8TGEr6r7c.png&ehk=PYIpC1OwRjT43jJFNDf4Sm%2fZwQG8HlG3Nw6Dk0P6z0Q%3d&risl=&pid=ImgRaw&r=0",
  ];

  if (!singleListing || popupCarousel !== "active") {
    return null;
  }

  const handleCloseClick = () => {
    dispatch(setPopupCarousel("disabled"));
  };
  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1000",
      }}
    >
      <div
        style={{
          position: "relative",
          textAlign: "center",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <FaTimes
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
            color: "white",
            fontSize: "30px",
            zIndex: "1001",
          }}
          onClick={handleCloseClick}
        />
        <div>
          <Carousel>
            {(singleListing.images || defaultImages).map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                  }}
                  src={image}
                  alt={`Listing image number ${index + 1}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div
          style={{
            padding: "10px 20px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            color: "black",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          <h5>
            For Rent: ${singleListing.monthRent} ({singleListing.numberOfBeds}{" "}
            beds, {singleListing.numberOfBaths} baths, {singleListing.sqft}{" "}
            Square Feet)
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ListingImagesCarousel;
