/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
import { setRentData } from "../State/Actions/gridActions.js";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Container, Row, Col, Card } from "react-bootstrap";
/*        4. Rent Scoop Libraries     */
import InitialPopup from "./Components/InitialPopup.jsx";
import MapComponent from "./Components/Map.jsx";
import MenuBar from "./Components/MenuBar.jsx";
import RentAgGrid from "./Components/Grid.jsx";
// import RentScatterChart from "./Components/ScatterChart.jsx";
import * as environment from "../env.js";
import "./HomePage.css";
import TheNavbar from "../NavBar/Navbar.jsx";
const HomePage = () => {
  const [user, setUser] = useState({});
  const savedMapData = JSON.parse(localStorage.getItem("mapData"));
  const city =
    useSelector((state) => state.map.address) || savedMapData.city || "Lowell";
  const state =
    useSelector((state) => state.map.state) || savedMapData?.state || "MA";
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
        axios
          .get(environment.domain + "/records/getRentalRecords")
          .then((response) => {
            dispatch(setRentData(response.data.data));
          });
      });
  }, []);
  return (
    <>
      <InitialPopup />
      <TheNavbar user={user} />
      <MenuBar />
      <Container fluid>
        <Row>
          <Col style={{ paddingLeft: 0, paddingRight: 0 }} xs={12} md={6}>
            <Card style={{ borderRadius: 0 }}>
              <MapComponent />
            </Card>
          </Col>

          <Col style={{ paddingLeft: 0, paddingRight: 0 }} xs={12} md={6}>
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Col>{/* <RentScatterChart /> */}</Col>
              <div
                className="ag-theme-material"
                style={{ height: "80vh", width: "100%" }}
              >
                <RentAgGrid />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default HomePage;
