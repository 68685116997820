/*           1. React Libraries       */
import React, { useEffect } from "react";
/*           2. Redux Libraries       */
import store from "./State/store";
import { Provider } from "react-redux";
/*           3. Third Party           */
import "mapbox-gl/dist/mapbox-gl.css";
import MyRoutes from "./router.jsx";
import ReactGA from "react-ga";
import axios from "axios";
import * as environment from "./env.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  ReactGA.initialize("G-6C13G7GSFJ");
  return (
    <Provider store={store}>
      <MyRoutes />
    </Provider>
  );
}

export default App;
