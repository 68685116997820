/*           1. React Libraries       */
import React from "react";
import { useState, useEffect } from "react";
/*           3. Third Party           */
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const InitialPopup = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const handleViewMapClick = () => {
    setIsPopupOpen(false);
    navigate("/listings");
  };

  const handleAddDataClick = () => {
    setIsPopupOpen(false);
    navigate("/addRental");
  };
  useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedListingsBefore");
    if (!visitedBefore) {
      setIsPopupOpen(true);
      localStorage.setItem("visitedListingsBefore", true);
    }
  }, []);
  return (
    <>
      {isPopupOpen && (
        <div className="background">
          <div className="centered">
            <h2>Welcome to Rent Scoop!</h2>
            <p>
              This is the listings page where you can find an off campus
              apartment for you and your friends! If Rent Scoop is helpful in
              your search, join the community and contribute to make renting
              easier for everyone.
            </p>
            <div>
              <Button
                variant="primary"
                className="mx-2"
                onClick={handleViewMapClick}
              >
                View Map
              </Button>
              <Button
                variant=""
                className="add-yours-popup-button"
                onClick={handleAddDataClick}
              >
                + Add Yours
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default InitialPopup;
