/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
/*           3. Rent Scoop            */
import "./AboutPage.css";
import TheNavbar from "../NavBar/Navbar.jsx";
import axios from "axios";
import * as environment from "../env.js";

const AboutUsPage = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>About Us | Rent Scoop</title>
        <meta
          name="description"
          content={`Rent Scoop is a free platform that combines user-submitted rental data with listings from multiple services to help renters find fair prices. Our goal is to create a transparent and equitable rental market by empowering renters and landlords with the same information.`}
        />
        <meta
          name="keywords"
          content={`Fair rent price in my area, rental records, rent price, fair rent, transparent rental information, Rent Scoop`}
        />
      </Helmet>
      <TheNavbar user={user} />
      <div
        style={{
          backgroundColor: "#333399",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Container className="pt-5 text-center">
          <h2 className="mb-4 font-weight-bold">Mission Statement</h2>
          <div className="d-flex justify-content-center flex-wrap">
            <p className="w-100 about-text">
              Rent Scoop was founded from our frustrations with finding accurate
              rent prices in our area. Even after months of research, we were
              left with very incomplete data.
            </p>
            <p className="w-100 about-text">
              When we realized that this was a common problem for many renters,
              seeing that crucial data was hidden behind paywalls or not easily
              accessible, we knew we could start something to fix it.
            </p>
            <p className="w-100 about-text">
              We believe that access to a community-driven data set is key to
              making informed renting and listing decisions. By building and
              sharing this data set with everyone, we can bring clarity to the
              renting process and ease the headaches around negotiating rent.
            </p>
            <p className="w-100 about-text">
              If you share our frustration and believe in our mission of rent
              transparency, we invite you to contribute and help spread our
              message to the renting world.
            </p>
            <p className="w-100 about-text about-signature">
              <span className="font-italic float-right">- Rent Scoop Team</span>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};
export default AboutUsPage;
