import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux"; // Assuming you are using Redux here
import { Helmet } from "react-helmet";
import SignInPage from "./Components/SignInPage";
import SignUpPage from "./Components/SignUpPage";
import ForgotPasswordPage from "./Components/ForgotPasswordPage";
import TheNavbar from "../NavBar/Navbar.jsx";
import axios from "axios";
import * as environment from "../env.js";
import "./LoginPage.css";

const LoginPage = () => {
  const loginType = useSelector((state) => state.login.loginPageType);
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);
  // Render the appropriate component based on loginType
  let loginComponent;
  if (loginType === "signIn") {
    loginComponent = <SignInPage />;
  } else if (loginType === "signUp") {
    loginComponent = <SignUpPage />;
  } else if (loginType === "forgotPassword" || loginType === "resetPassword") {
    loginComponent = <ForgotPasswordPage />;
  } else {
    // Handle the case if loginType is not recognized
    loginComponent = <p>Invalid loginType!</p>;
  }

  return (
    <>
      <Helmet>
        <title>Rent Scoop | Login </title>
        <meta
          name="description"
          content={`Sign up today to contribute your own listings and have access to our premium features by purchasing one of our subscriptions!`}
        />
        <meta
          name="keywords"
          content={`Rent scoop sign in, Rent scoop login`}
        />
      </Helmet>
      <TheNavbar user={user} />
      <div className="sign-in-page">
        {/* <div className="left-side">
          <h1>Why Sign Up</h1>
          <ul>
            <li>
              <strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing elit.
            </li>
          </ul>
        </div> */}
        <div className="right-side-login">{loginComponent}</div>
      </div>
    </>
  );
};

export default LoginPage;
