/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
import { setRentData } from "../../State/Actions/gridActions.js";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Container, Row, Col, Card } from "react-bootstrap";
/*        4. Rent Scoop Libraries     */
import MenuBar from "./RecordsComponents/MenuBar.jsx";
import RentAgGrid from "./RecordsComponents/Grid.jsx";
// import RentScatterChart from "./RecordsComponents/ScatterChart.jsx";
const Records = (props) => {
  return (
    <div style={{ widht: "100%" }}>
      <MenuBar />

      <Col xs={12}>
        <Card
          style={{
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Col>{/* <RentScatterChart /> */}</Col>
          <div
            className="ag-theme-material"
            style={{ height: "80vh", width: "100%" }}
          >
            <RentAgGrid props={props} />
          </div>
        </Card>
      </Col>
    </div>
  );
};
export default Records;
