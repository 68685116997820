/*           1. React Libraries       */
import { createSlice, combineReducers } from "@reduxjs/toolkit";

// Rent Data
const rentDataSlice = createSlice({
  name: "rentData",
  initialState: [],
  reducers: {
    setRentData(state, action) {
      return action.payload;
    },
  },
});

// Grid Ref
const gridRefSlice = createSlice({
  name: "gridRef",
  initialState: null,
  reducers: {
    setGridRef(state, action) {
      return action.payload;
    },
  },
});

// Exports
export const gridReducers = combineReducers({
  rentData: rentDataSlice.reducer,
  gridRef: gridRefSlice.reducer,
});
export const { setRentData } = rentDataSlice.actions;
export const { setGridRef } = gridRefSlice.actions;
