/*           1. Third Party           */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
/*           2. Rent Scoop            */
import { gridReducers } from "./Actions/gridActions";
import { mapReducers } from "./Actions/mapActions";
import { loginReducers } from "./Actions/loginActions";
import { listingReducers } from "./Actions/listingActions";
import { universityReducers } from "./Actions/universityActions";

// Reducers
const rootReducer = combineReducers({
  grid: gridReducers,
  map: mapReducers,
  login: loginReducers,
  listings: listingReducers,
  university: universityReducers,
});

// Main Store Export
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
