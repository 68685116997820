import { createSlice, combineReducers } from "@reduxjs/toolkit";

// Login page type
const loginPageTypeSlice = createSlice({
  name: "loginPageType",
  initialState: "signIn",
  reducers: {
    setLoginType(state, action) {
      return action.payload;
    },
  },
});

// Exports
export const loginReducers = combineReducers({
  loginPageType: loginPageTypeSlice.reducer,
});
export const { setLoginType: setLoginType } = loginPageTypeSlice.actions;
