/*           1. React Libraries       */
import React, { useEffect, useRef } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
import { setMap } from "../../State/Actions/mapActions.js";
import {
  setDetailType,
  setSingleRental,
} from "../../State/Actions/listingActions";
/*           3. Third Party           */
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
/*        4. Rent Scoop Libraries     */
import * as environment from "../../env.js";

mapboxgl.accessToken = environment.mapboxgl;

const MapComponent = () => {
  // Local Storage
  const savedMapData = JSON.parse(localStorage.getItem("mapData"));
  const mapContainer = useRef(null);
  // Redux
  const listings = useSelector((state) => state.listings.rentListing);
  const longitude =
    useSelector((state) => state.map.longitude) ||
    savedMapData.longitude ||
    -71.3258;
  const latitude =
    useSelector((state) => state.map.latitude) ||
    savedMapData.latitude ||
    42.65156;
  const map = useSelector((state) => state.map.map);
  const dispatch = useDispatch();

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [longitude, latitude],
      zoom: 12,
    });
    dispatch(setMap(map));
    const toggleControl = document.createElement("div");
    toggleControl.className = "toggle-control";

    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.GeolocateControl());

    map.on("load", () => {
      map.resize();
    });
  }, []);

  useEffect(() => {
    buildAndAttachMapMarkers(listings, map);
  }, [listings]);

  useEffect(() => {
    if (map && longitude !== 0 && latitude !== 0) {
      map.flyTo({
        center: [longitude, latitude],
        zoom: 12,
      });
    }
  }, [longitude, latitude]);

  function buildAndAttachMapMarkers(listings, map) {
    const markers = document.getElementsByClassName("marker-element");
    while (markers.length > 0) {
      markers[0].parentNode.removeChild(markers[0]);
    }

    listings.forEach((rental) => {
      const markerElement = document.createElement("div");
      markerElement.className = "marker-element";
      markerElement.innerText = "$" + rental.monthRent;
      markerElement.style.backgroundColor = "#333399";
      markerElement.style.color = "white";
      markerElement.style.borderRadius = "10px";

      const addressParse = rental.address.split(",");
      const address = addressParse.slice(0, 2);

      const popupContent = `
  <div id="popup-content">
    <p style="margin-bottom: -5px;"><strong>${address.join(", ")}</strong></p>
    <p style="margin-bottom: -5px;"><strong>Rent:</strong> $${
      rental.monthRent
    }</p>
    <p style="margin-bottom: -5px;">$${(
      rental.monthRent / rental.numberOfBeds
    ).toFixed(0)}/bed</p>
    <p style="margin-bottom: -5px;">${
      rental.numberOfBeds
    } bd &nbsp&nbsp|&nbsp&nbsp${rental.numberOfBaths} ba</p>
    ${
      rental.utilitiesIncluded
        ? `<p style="margin-bottom: -5px;"><strong>Included Utilities:</strong> ${rental.utilitiesIncluded}</p>`
        : ""
    }
    ${
      rental.perks
        ? `<p style="margin-bottom: -5px;"><strong>Perks:</strong> ${rental.perks}</p>`
        : ""
    }
    ${
      rental.sqft
        ? `<p style="margin-bottom: -5px;"><strong>SqFt:</strong> ${rental.sqft}</p>`
        : ""
    }
  </div>
`;

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([rental.longitude, rental.latitude])
        .setPopup(popup)
        .addTo(map);

      markerElement.addEventListener("click", () => {
        dispatch(setDetailType("descriptive"));
        dispatch(setSingleRental(rental));
        marker.togglePopup();
      });
    });
  }
  return (
    <div>
      <div className="map-container" ref={mapContainer} />
    </div>
  );
};

export default MapComponent;
