/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Third Party           */
import { Container, Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import TheNavbar from "../NavBar/Navbar.jsx";
import axios from "axios";
import * as environment from "../env.js";

const FAQPage = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(environment.domain + "/user/getSessionUser", {
        withCredentials: true, // needed for sessions
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>FAQ | Rent Scoop</title>
        <meta
          name="description"
          content={`View our most commonly asked questions!`}
        />
        <meta
          name="keywords"
          content={`rent faq, renting questions, how to rent a property`}
        />
      </Helmet>
      <TheNavbar user={user} />
      <div
        style={{
          backgroundColor: "#333399",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ padding: "2rem" }}
        >
          <Accordion defaultActiveKey="0">
            <h2 className="text-center mb-4 font-weight-bold">FAQ's</h2>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                What makes Rent Scoop so unique compared to similar websites?
              </Accordion.Header>
              <Accordion.Body>
                RentScoop is unique compared to existing listing websites
                because it provides a permanent, community-driven data set for
                accurate rent prices. On existing platforms, listings go away
                after they are filled, along with their important data that
                could help other renters in the area. What sets us apart is that
                our data is public and sustained, ensuring that renters and
                property owners alike have open access to reliable information.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How do I add a record or listing?
              </Accordion.Header>
              <Accordion.Body>
                In order to upload a listing or record you must first sign up
                for a free Rent Scoop account. From here a dropdown in the top
                right will allow you to select the "Add Record" or "Create
                Listing" page.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Why don't I see my information on the Universities Page?
              </Accordion.Header>
              <Accordion.Body>
                If you are a landlord or student looking to appear in your
                respective university page ensure that you have the "Search for
                Roommate" or "Appear on University Page" options enabled. This
                ensures that you are not only tied to a university, but also
                guarantees your visibility on the page. The same applies for the
                "Display Email" and "Display Phone Number" checkboxes for
                landlords and their respective listings.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What does clicking the heart on a listing do?
              </Accordion.Header>
              <Accordion.Body>
                Clicking the heart on a listing simply saves it to your "Saved
                Listings" folder that can be accessed within the "My Account"
                page in the drop down under your username. This allows you to
                create your own custom search page with all your favorite
                listings!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Can I change my username or email?
              </Accordion.Header>
              <Accordion.Body>
                Unfortunately at the moment Rent Scoop does not support changing
                your username or email that were set on sign up. Any edits to
                the name and email fields when editing your user profile are
                solely for display purposes. To login you will have to use
                either the email or username you registered with. We apologize
                for the inconvenience as we work to improve this in the near
                future.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>How can I help?</Accordion.Header>
              <Accordion.Body>
                This is a great question! Aside from submitting any potentially
                helpful data, sharing the word about Rent Scoop to others is a
                great way to contribute. Encouraging others to join us on the
                mission of creating a transparent renting experience is the best
                way this website grows. This includes reaching out to off campus
                landlords and really any private landlord interested in our
                mission. We appreciate all the help from our community and look
                forward to continuous growth throughout the year. As always feel
                free to contact us with any feedback and suggestions of what you
                would like to see on the site!
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </>
  );
};
export default FAQPage;
