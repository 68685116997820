/*           1. React Libraries       */
import { createSlice, combineReducers } from "@reduxjs/toolkit";

// Map
const mapSlice = createSlice({
  name: "map",
  initialState: null,
  reducers: {
    setMap(state, action) {
      return action.payload;
    },
  },
});

// Longitude
const longitudeSlice = createSlice({
  name: "longitude",
  initialState: -71.3258,
  reducers: {
    setLongitude(state, action) {
      return action.payload;
    },
  },
});

// Latitude
const latitudeSlice = createSlice({
  name: "latitude",
  initialState: 42.65156,
  reducers: {
    setLatitude(state, action) {
      return action.payload;
    },
  },
});

// City
const addressSlice = createSlice({
  name: "address",
  initialState: "Lowell",
  reducers: {
    setAddress(state, action) {
      return action.payload;
    },
  },
});

const stateSlice = createSlice({
  name: "state",
  initialState: "",
  reducers: {
    setState(state, action) {
      return action.payload;
    },
  },
});

// Exports
export const mapReducers = combineReducers({
  map: mapSlice.reducer,
  longitude: longitudeSlice.reducer,
  latitude: latitudeSlice.reducer,
  address: addressSlice.reducer,
  state: stateSlice.reducer,
});
export const { setMap } = mapSlice.actions;
export const { setLongitude } = longitudeSlice.actions;
export const { setLatitude } = latitudeSlice.actions;
export const { setAddress } = addressSlice.actions;
export const { setState } = stateSlice.actions;
