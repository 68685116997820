import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginType } from "../../State/Actions/loginActions";
import { ToastAlert } from "../../Toast";
import axios from "axios";
import * as environment from "../../env.js";
import "../LoginPage.css";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signinType = useSelector((state) => state.login.loginPageType);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [code, setCode] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password, password2]);

  function gotoSignIn() {
    dispatch(setLoginType("signIn"));
  }

  function sendCode() {
    let usernameInfo = { username: email };
    axios
      .post(environment.domain + "/user/forgotPassword", usernameInfo)
      .then((response) => {
        ToastAlert("Password reset code sent successfully.", "success");
        dispatch(setLoginType("resetPassword"));
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }

  function resetPassword() {
    if (!passwordsMatch) {
      ToastAlert("Passwords do not match", "error");
      return;
    }

    let passwordInfo = {
      username: email,
      newPassword: password,
      confirmationCode: code,
    };

    axios
      .post(environment.domain + "/user/resetPassword", passwordInfo)
      .then((response) => {
        ToastAlert("Successfully reset password!", "info");
        dispatch(setLoginType("signIn"));
      })
      .catch((error) => {
        let errorMessage =
          "An unexpected server error occurred. Please try again later.";

        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.error ||
            error.response.data.message ||
            errorMessage;
        }
        ToastAlert(errorMessage, "error");
      });
  }

  return (
    <>
      {signinType === "resetPassword" ? (
        <div className="login-box">
          <img
            src="https://rentscoop.s3.amazonaws.com/branding/Rent-Scoop-First.svg"
            alt="Rent Scoop Logo"
            className="rent-scoop-logo-login"
          />
          <input
            className="custom-input"
            type="text"
            id="code"
            placeholder="Enter Code"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
          <input
            className="custom-input"
            type="password"
            id="password"
            placeholder="Enter New Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <input
            className="custom-input"
            type="password"
            id="password2"
            placeholder="Confirm New Password"
            value={password2}
            onChange={(event) => setPassword2(event.target.value)}
          />
          {!passwordsMatch && (
            <p style={{ color: "red" }}>Passwords do not match</p>
          )}
          <button
            className="login-buttons"
            onClick={() => resetPassword()}
            // disabled={!passwordsMatch}
          >
            Reset Password
          </button>
          <div className="bottom-links">
            <p>
              Already have an account?{" "}
              <a
                onClick={() => gotoSignIn()}
                style={{ textDecoration: "underline" }}
              >
                Sign In
              </a>{" "}
              Here
            </p>
          </div>
        </div>
      ) : (
        <div className="login-box">
          <img
            src="https://amplify-amplifya7b38c5bab1a4-staging-222129-deployment.s3.amazonaws.com/Rent-Scoop-First.svg"
            alt="Rent Scoop Logo"
            className="rent-scoop-logo-login"
          />
          <input
            className="custom-input"
            type="text"
            id="email"
            placeholder="Enter Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <button className="login-buttons" onClick={() => sendCode()}>
            Send Code
          </button>
          <div className="bottom-links">
            <p>
              <a
                onClick={() => gotoSignIn()}
                style={{ textDecoration: "underline" }}
              >
                Back to login
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordPage;
