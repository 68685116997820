/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
import { useDispatch, useSelector } from "react-redux";
/*           3. Third Party           */
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // CSS
import "ag-grid-community/styles/ag-theme-material.css"; // CSS
import "mapbox-gl/dist/mapbox-gl.css"; // CSS
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // CSS
import { Container, Row, Col, Card, Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import * as environment from "../../env.js";
import { setPopupCarousel } from "../../State/Actions/universityActions";
import ListingImagesCarousel from "./ListingsComponents/ListingImagesCarousel.jsx";
import MapComponent from "./ListingsComponents/Map.jsx";
const SingleStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { universityID, studentID } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    axios
      .get(`${environment.domain}/user/getDetailedUser?user=${studentID}`, {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching university details:", error);
      });
  }, []);
  const handleBackClick = () => {
    navigate(`/universities/${universityID}/roommates/`);
  };
  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        {" "}
        {/* Ensure this div spans the full page width */}
        <FaTimes
          size={24}
          color="white"
          style={{
            position: "absolute", // Position absolutely within the relative container
            top: "16px", // Adjust as needed for your layout
            right: "16px", // Adjust as needed for your layout
            cursor: "pointer",
            zIndex: 10, // Ensure it's above other elements
          }}
          onClick={handleBackClick}
        />
        <Container className="d-flex justify-content-center mt-2">
          <Card
            style={{
              borderRadius: "10px",
              backgroundColor: "#3399FF",
              border: "0px",
              maxWidth: "75%", // Adjust card size as necessary
            }}
          >
            <Card.Body>
              <div className="d-flex flex-column align-items-center">
                <img
                  src={
                    user?.pfp ||
                    "https://yourteachingmentor.com/wp-content/uploads/2020/12/istockphoto-1223671392-612x612-1.jpg"
                  }
                  alt="Profile"
                  style={{
                    width: "150px", // Adjust image size as necessary
                    height: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginBottom: "10px", // Gives some space below the image
                  }}
                />
                <div style={{ color: "white", textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {user?.name}
                  </p>
                  <p style={{ fontSize: "14px" }}>{user?.bio}</p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginTop: "-20px",
                      marginBottom: "24px",
                    }}
                  >
                    <strong>Likes: </strong>
                    {user?.likes}
                  </p>
                  {user?.contact?.displayEmail && user?.contact?.email && (
                    <p style={{ fontSize: "14px", marginTop: "-20px" }}>
                      <strong>Email: </strong>
                      {user?.contact?.email}
                    </p>
                  )}
                  {user?.contact?.displayPhone && user?.contact?.phone && (
                    <p style={{ fontSize: "14px", marginTop: "-20px" }}>
                      <strong>Phone: </strong>
                      {user?.contact?.phone}
                    </p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SingleStudent;
