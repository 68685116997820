/*           1. React Libraries       */
import React, { useState, useEffect } from "react";
/*           2. Redux Libraries       */
/*           3. Third Party           */
/*        4. Rent Scoop Libraries     */
import { Card, Row, Col } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const Roommates = (props) => {
  const university = props.university;
  const students = university?.students || [];
  const navigate = useNavigate();
  const { universityID } = useParams();
  const handleListingClick = (listing) => {
    navigate(`/universities/${universityID}/roommates/${listing._id}`);
  };

  return (
    <>
      <div className="standard-detail-view">
        {/* First Row */}
        <Row>
          <Col>
            <h1
              style={{ fontWeight: "bold", fontSize: "28px", color: "white" }}
            >
              {university.name} Students looking for roommates!
            </h1>
          </Col>
        </Row>
        {/* Listings */}
        <Row>
          {students.map((student, index) => (
            <StudentCard
              key={student.id | index}
              student={student}
              handleListingClick={handleListingClick}
            />
          ))}
        </Row>
      </div>
    </>
  );
};
export default Roommates;

const defaultImage = [
  "https://yourteachingmentor.com/wp-content/uploads/2020/12/istockphoto-1223671392-612x612-1.jpg",
];

const StudentCard = ({ student: student, handleListingClick }) => {
  const imageToUse = student?.pfp || defaultImage;

  return (
    <Col
      md={3}
      className="listing-col"
      style={{ padding: "0.5%", margin: "0" }}
    >
      <Card
        className="listing-card"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "275px",
          overflow: "hidden",
          margin: "0",
          padding: "0",
          cursor: "pointer",
        }}
        onClick={() => handleListingClick(student)}
      >
        <div style={{ overflow: "hidden" }}>
          <img
            src={student?.pfp || imageToUse}
            alt="Landlord Cover"
            style={{ width: "100%", minHeight: "200px", objectFit: "cover" }}
          />
        </div>
        <Card.Body style={{ marginTop: "-10px", height: "30%" }}>
          <Card.Title style={{ marginBottom: "1px", fontWeight: "bold" }}>
            {student?.name}
          </Card.Title>
          <Card.Text
            style={{ marginBottom: "1px", padding: "0", fontSize: "smaller" }}
          >
            {student?.bio}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
